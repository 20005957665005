import React, { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import MaterialTable, { MTableCell } from 'material-table';
import moment from 'moment';
import { jobService } from '../services/jobService';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import JobAddDialog from '../dialogs/JobAddDialog';
import {
  DevisStatus,
  DevisStatusFR,
  DevisStatusList,
} from '../helpers/devisStatus';
import { JobStatus, JobStatusFR, JobStatusList } from '../helpers/jobStatus';
import { Localization, getDelimiter } from '../helpers/localization';
import {
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { getTableBodyHeights } from '../helpers/table';

function Jobs() {
  const classes = useStyles();
  const history = useHistory();
  const [total, setTotal] = useState({
    buy: 0,
    sell: 0,
    profit: 0,
    remainingFactures: 0,
    remainingToPay: 0,
    factures: 0,
    rg: 0,
  });
  const columns = [
    {
      title: 'Date devis',
      field: 'devisDate',
      render: (rowData) => {
        return (
          rowData.devisDate && (
            <div>{moment(rowData.devisDate).format('DD.MM.YYYY')}</div>
          )
        );
      },
      cellStyle: { paddingLeft: 16 },
    },
    {
      title: 'Devis statut',
      field: 'devisStatus',
      render: (rowData) => <div>{DevisStatusFR[rowData.devisStatus]}</div>,
      customFilterAndSearch: (term, rowData) =>
        DevisStatusFR[rowData.devisStatus]
          .toUpperCase()
          .includes(term.toUpperCase()),
    },
    {
      title: 'Client',
      field: 'clientName',
      cellStyle: { maxWidth: '150px', overflowWrap: 'break-word' },
    },
    {
      title: 'Affaire',
      field: 'name',
      cellStyle: { maxWidth: '150px', overflowWrap: 'break-word' },
    },
    {
      title: 'Début',
      field: 'startDate',
      render: (rowData) => {
        return (
          rowData.startDate && (
            <div>{moment(rowData.startDate).format('DD.MM.YYYY')}</div>
          )
        );
      },
    },
    {
      title: 'Statut',
      field: 'status',
      render: (rowData) => <div>{JobStatusFR[rowData.status]}</div>,
      customFilterAndSearch: (term, rowData) =>
        JobStatusFR[rowData.status].toUpperCase().includes(term.toUpperCase()),
    },
    {
      title: `Reste à facturer [${Number(
        total.remainingFactures.toFixed(2)
      ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}]`,
      field: 'remainingFactures',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
  ];
  const columnsDetails = [
    {
      title: 'Date devis',
      field: 'devisDate',
      render: (rowData) => {
        return (
          rowData.devisDate && (
            <div>{moment(rowData.devisDate).format('DD.MM.YYYY')}</div>
          )
        );
      },
      cellStyle: { paddingLeft: 16 },
    },
    {
      title: 'Devis statut',
      field: 'devisStatus',
      render: (rowData) => <div>{DevisStatusFR[rowData.devisStatus]}</div>,
      customFilterAndSearch: (term, rowData) =>
        DevisStatusFR[rowData.devisStatus]
          .toUpperCase()
          .includes(term.toUpperCase()),
    },
    {
      title: 'Client',
      field: 'clientName',
      cellStyle: { maxWidth: '150px', overflowWrap: 'break-word' },
    },
    {
      title: 'Affaire',
      field: 'name',
      cellStyle: { maxWidth: '150px', overflowWrap: 'break-word' },
    },
    {
      title: 'Début',
      field: 'startDate',
      render: (rowData) => {
        return (
          rowData.startDate && (
            <div>{moment(rowData.startDate).format('DD.MM.YYYY')}</div>
          )
        );
      },
    },
    {
      title: 'Fin',
      field: 'endDate',
      render: (rowData) => {
        return (
          rowData.endDate && (
            <div>{moment(rowData.endDate).format('DD.MM.YYYY')}</div>
          )
        );
      },
    },
    {
      title: 'Statut',
      field: 'status',
      render: (rowData) => <div>{JobStatusFR[rowData.status]}</div>,
      customFilterAndSearch: (term, rowData) =>
        JobStatusFR[rowData.status].toUpperCase().includes(term.toUpperCase()),
    },
    {
      title: `Dépenses [${Number(total.buy.toFixed(2)).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      })}]`,
      field: 'totalBuy',
      type: 'currency',
      currencySetting: {
        currencyCode: 'EUR',
        locale: 'fr-FR',
      },
      headerStyle: { textAlign: 'right' },
    },
    {
      title: `Vente [${Number(total.sell.toFixed(2)).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      })}]`,
      field: 'totalSell',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
    {
      title: `Marge [${Number(total.profit.toFixed(2)).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      })}]`,
      field: 'totalProfit',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
    {
      title: `Total facturation [${Number(
        total.factures.toFixed(2)
      ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}]`,
      field: 'totalFactures',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
    {
      title: `Reste à facturer [${Number(
        total.remainingFactures.toFixed(2)
      ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}]`,
      field: 'remainingFactures',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
    {
      title: `Solde à payer [${Number(
        total.remainingToPay.toFixed(2)
      ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}]`,
      field: 'remainingToPay',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
    { title: 'RG payé', field: 'rgCollected', type: 'boolean' },
    {
      title: `Montant RG [${Number(total.rg.toFixed(2)).toLocaleString(
        'fr-FR',
        { style: 'currency', currency: 'EUR' }
      )}]`,
      field: 'totalRg',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
    },
  ];

  const options = {
    exportButton: true,
    exportFileName: 'travaux',
    exportDelimiter: getDelimiter(),
    draggable: false,
    actionsColumnIndex: -1,
    pageSizeOptions: [10, 25, 50],
    paging: true,
    pageSize: 10,
    ...getTableBodyHeights(),
    rowStyle: (rowData) => {
      if (
        rowData.rgCollected === false &&
        moment(rowData.rgDate).isValid() &&
        moment(rowData.rgDate).isBefore(moment(new Date()))
      ) {
        return {
          backgroundColor: '#FFA726',
        };
      }
    },
    exportPdf: () => {},
  };
  const actions = [
    {
      icon: 'add_box',
      tooltip: 'Ajouter',
      isFreeAction: true,
      onClick: (event) => {
        setOpenAdd(true);
      },
    },
    () => ({
      icon: 'more_horiz',
      tooltip: 'Détails',
      onClick: (event, rowData) => {
        history.push(String(rowData.id));
      },
    }),
    () => ({
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => {
        setDeleteId(rowData.id);
        setConfirmOpen(true);
      },
    }),
  ];

  const [jobToAdd, setJobToAdd] = useState({
    clientName: '',
    clientContact: '',
    name: '',
    address: '',
    status: JobStatus.Paused,
    devisStatus: DevisStatus.InProgress,
    devisDate: null,
    startDate: null,
    endDate: null,
    rgDate: null,
    rgCollected: false,
  });
  const handleAddJob = () => {
    handleCloseAdd();
    jobService.create(jobToAdd).then(() => {
      getJobs();
    });
  };
  const [openAdd, setOpenAdd] = useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setJobToAdd({
      clientName: '',
      clientContact: '',
      name: '',
      address: '',
      status: JobStatus.Paused,
      devisStatus: DevisStatus.InProgress,
      devisDate: null,
      startDate: null,
      endDate: null,
      rgDate: null,
      rgCollected: false,
    });
  };

  const [deleteId, setDeleteId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const handleDeleteJob = () => {
    jobService.deleteJob(deleteId).then(() => {
      handleConfirmClose();
      getJobs();
    });
  };

  const [data, setData] = useState([]);
  const getJobs = (params) => {
    setLoading(true);
    jobService.getAll(params).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    setTotal({
      buy: data.map((x) => x.totalBuy).reduce((a, b) => a + b, 0),
      sell: data.map((x) => x.totalSell).reduce((a, b) => a + b, 0),
      profit: data.map((x) => x.totalProfit).reduce((a, b) => a + b, 0),
      remainingFactures: data
        .map((x) => x.remainingFactures)
        .reduce((a, b) => a + b, 0),
      remainingToPay: data
        .map((x) => x.remainingToPay)
        .reduce((a, b) => a + b, 0),
      factures: data.map((x) => x.totalFactures).reduce((a, b) => a + b, 0),
      rg: data.map((x) => x.totalRg).reduce((a, b) => a + b, 0),
    });
  }, [data]);

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    details: false,
    status: '',
    devisStatus: '',
    date: null,
  });
  const handleCheckboxChange = (name) => (event) => {
    var value;
    value = event.target.checked;
    setFilter({ ...filter, [name]: value });
  };

  useEffect(() => {
    getJobs([
      {
        name: 'startDate',
        value: filter.date ? moment(filter.date).format('YYYY-01-01') : null,
      },
      {
        name: 'endDate',
        value: filter.date
          ? moment(filter.date).add(1, 'years').format('YYYY-01-01')
          : null,
      },
      {
        name: 'status',
        value: filter.status ? filter.status : null,
      },
      {
        name: 'devisStatus',
        value: filter.devisStatus ? filter.devisStatus : null,
      },
    ]);
  }, [filter.date, filter.status, filter.devisStatus]);

  const handleDateChange = (date) => {
    if (!moment(date).isValid()) {
      setFilter({ ...filter, date: null });
    } else {
      setFilter({ ...filter, date: date });
    }
  };

  const handleStatusChange = (name) => (e) => {
    const value = e.target.value;
    setFilter({ ...filter, [name]: value });
  };

  const dateString = filter.date
    ? `01.01.${moment(filter.date).format('YYYY')} - 31.12.${moment(
        filter.date
      ).format('YYYY')}`
    : '';

  return (
    <Dashboard maxWidth={filter.details ? false : 0} title={'Travaux'}>
      <MaterialTable
        key={Number(filter.details)}
        columns={filter.details ? columnsDetails : columns}
        data={data}
        options={options}
        actions={actions}
        title={
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
              <DatePicker
                clearable
                clearLabel='Supprimer'
                cancelLabel='Annuler'
                views={['year']}
                label="Sélectionnez l'année"
                labelFunc={() => dateString}
                className={classes.datePicker}
                value={filter.date}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
            <FormControlLabel
              className={classes.filterItem}
              control={
                <Checkbox
                  checked={filter.details}
                  onChange={handleCheckboxChange('details')}
                  value={filter.details}
                  color='primary'
                />
              }
              label={<div className={classes.checkboxLabel}>Détails</div>}
            />
            <br />
            <FormControl margin='normal' className={classes.status}>
              <InputLabel>Chantier statut</InputLabel>
              <Select
                value={filter.status}
                onChange={handleStatusChange('status')}
              >
                <MenuItem value={''}>Tout</MenuItem>
                {JobStatusList.map((status, i) => (
                  <MenuItem key={i} value={status}>
                    {JobStatusFR[status]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin='normal' className={classes.status}>
              <InputLabel>Devis Statut</InputLabel>
              <Select
                value={filter.devisStatus}
                onChange={handleStatusChange('devisStatus')}
              >
                <MenuItem value={''}>Tout</MenuItem>
                {DevisStatusList.map((status, i) => (
                  <MenuItem key={i} value={status}>
                    {DevisStatusFR[status]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        }
        isLoading={loading}
        localization={Localization}
        components={{
          Cell: (props) => (
            <MTableCell {...props} style={{ padding: '0px 4px' }} />
          ),
        }}
      />
      <JobAddDialog
        open={openAdd}
        handleClose={handleCloseAdd}
        handleAdd={handleAddJob}
        jobToAdd={jobToAdd}
        setJobToAdd={setJobToAdd}
      />
      <ConfirmDeleteDialog
        open={confirmOpen}
        handleClose={handleConfirmClose}
        handleDelete={handleDeleteJob}
        text={'Confirmer la suppression de travail?'}
      />
    </Dashboard>
  );
}

export default Jobs;

const useStyles = makeStyles((theme) => ({
  datePicker: {
    minWidth: 180,
    width: 180,
  },
  filterItem: {
    marginTop: theme.spacing(1),
  },
  endItem: {
    flexGrow: 1,
  },
  status: {
    marginTop: 0,
    minWidth: 140,
    width: 140,
    marginRight: theme.spacing(1),
  },
}));
