import {
    Grid, makeStyles, Paper, TextField, Typography,
    Table, TableBody, TableRow, TableCell, TableHead
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    PieChart, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Pie, Cell, ResponsiveContainer,
} from 'recharts'
import Dashboard from '../Dashboard'
import { analysisService } from '../../services/analysisService'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { jobService } from '../../services/jobService';
import { JobStatus } from '../../helpers/jobStatus'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';

const AnalysisFiscal = () => {
    const [fiscalMonths, setFiscalMonths] = useState([])
    const [fiscalExpenses, setFiscalExpenses] = useState([])
    const [date, setDate] = useState(new Date())

    const classes = useStyles()

    const [job, setJob] = useState({
        id: null,
        name: '',
        clientName: ''
    })

    useEffect(() => {
        const getFiscalMonths = () => {
            analysisService.getFiscalMonths([
                {
                    name: "start",
                    value: moment(date).format("YYYY-01-01")
                },
                {
                    name: "end",
                    value: moment(date).add(1, 'years').format("YYYY-01-01")
                },
                {
                    name: "jobId",
                    value: job.id
                }
            ]).then(response => {
                let arr = []
                response.forEach(e => {
                    const month = e.month < 10 ? `0${e.month}` : e.month
                    arr.push({
                        month: `${month}.${e.year}.`,
                        'revenus': Math.round(e.incomes * 100) / 100,
                        'dépenses': Math.round(e.expenses * 100) / 100
                    })
                })
                const incomes = response.reduce((a, b) => a + b.incomes, 0)
                const expenses = response.reduce((a, b) => a + b.expenses, 0)
                const profit = incomes - expenses
                setTotal({
                    incomes,
                    expenses,
                    profit
                })
                setFiscalMonths(arr)
            })
        }
        const getFiscalExpenses = () => {
            analysisService.getFiscalExpenses([
                {
                    name: "start",
                    value: moment(date).format("YYYY-01-01")
                },
                {
                    name: "end",
                    value: moment(date).add(1, 'years').format("YYYY-01-01")
                },
                {
                    name: "jobId",
                    value: job.id
                }
            ]).then(response => {
                let arr = []
                response.forEach(e => {
                    arr.push({
                        name: e.name,
                        total: Math.round(e.total * 100) / 100,
                    })
                })
                setFiscalExpenses(prepareInputForPie(arr))
            })
        }
        
        getFiscalMonths()
        getFiscalExpenses()
    }, [date, job.id])

    const eurFormatter = (entry) => `${entry} €`

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#9999CC'];

    const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
        const RADIAN = Math.PI / 180
        const radius = 20 + innerRadius + (outerRadius - innerRadius)
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        const text = fiscalExpenses[index].name.length <= 15 ? fiscalExpenses[index].name : `${fiscalExpenses[index].name.slice(0, 15)}..`
        const percent = Number(value / fiscalExpenses.reduce((a, b) => a + b.total, 0) * 100).toFixed(2)
        if (percent < 10) {
            return null/*
                (<text x={x} y={y} fill='black' textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                    {percent}%
                </text>)
                */
        } else {
            return (
                <text x={x} y={y} fill='black' textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                    <tspan x={x} dy='-0.2em'>{text}</tspan>
                    <tspan x={x} dy='1.0em'>{`(${percent}%)`}</tspan>
                </text>
            )
        }
    }

    const handleJobChange = (event, newJob) => {
        if (newJob === null) {
            setJob({
                id: null,
                name: '',
                clientName: ''
            });
        } else {
            setJob(newJob);
        }
    }

    const [jobs, setJobs] = useState([])
    const getJobs = (params) => {
        jobService.getAll(params)
            .then(res => setJobs(res))
    }
    useEffect(() => {
        getJobs([{ name: 'status', value: JobStatus.Started },
        { name: "startDate", value: moment(date).format("YYYY-01-01") },
        { name: "endDate", value: moment(date).add(1, 'years').format("YYYY-01-01") }])
    }, [date])

    const handleDateChange = (date) => {
        if (!moment(date).isValid()) {
            setDate(new Date())
        } else {
            setDate(date)
        }
    }

    const dateString = `01.01.${moment(date).format('YYYY')} - 31.12.${moment(date).format('YYYY')}`

    const [total, setTotal] = useState({
        incomes: 0,
        expenses: 0,
        profit: 0
    })

    return (
        <Dashboard title={`Analyse chantier ${job.id ? `- ${job.name} (${job.clientName})` : ''}`}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper className={classes.paper}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                            <DatePicker
                                cancelLabel="Annuler"
                                views={["year"]}
                                label="Sélectionnez l'année"
                                labelFunc={() => dateString}
                                className={classes.datePicker}
                                value={date}
                                onChange={handleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                        <div>
                            <Autocomplete
                                className={classes.autocomplete}
                                options={jobs}
                                getOptionLabel={job => job.clientName === '' ? '' : `${job.name} (${job.clientName})`}
                                onChange={handleJobChange}
                                value={job}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={`Sélectionnez le travail`}
                                        margin="dense"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        <Table size="small" className={classes.table} >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} padding='none' align='center'>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ borderBottom: 'none' }}>
                                    <TableCell align='right' width={70} padding='none'>Revenus:</TableCell>
                                    <TableCell align='right'>{Number(total.incomes).toFixed(2)} €</TableCell>
                                </TableRow>
                                <TableRow style={{ borderBottom: 'none' }}>
                                    <TableCell align='right' width={70} padding='none'>Dépenses:</TableCell>
                                    <TableCell align='right'>{Number(total.expenses).toFixed(2)} €</TableCell>
                                </TableRow>
                                <TableRow style={{ borderBottom: 'none' }}>
                                    <TableCell align='right' width={70} padding='none'>Profit:</TableCell>
                                    <TableCell align='right'>{Number(total.profit).toFixed(2)} €</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.title} variant="h6">
                            Dépenses {dateString}
                        </Typography>
                        <ResponsiveContainer width='100%' height='100%'>
                            <PieChart>
                                <Pie
                                    data={fiscalExpenses}
                                    dataKey="total"
                                    cx="50%"
                                    cy="40%"
                                    outerRadius={75}
                                    label={renderCustomLabel}
                                    labelLine={false}
                                    isAnimationActive={false}>
                                    {
                                        fiscalExpenses.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>               
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paperHigh}>
                        <Typography className={classes.title} variant="h6">
                            Dépenses et revenus mensuel {dateString}
                        </Typography>
                        <ResponsiveContainer width='95%' height='87%' >
                            <BarChart data={fiscalMonths} margin={{ left: 24 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis tickFormatter={eurFormatter} width={80} domain={[0, dataMax => dataMax]} />
                                <Tooltip />
                                <Legend verticalAlign='bottom' />
                                <Bar dataKey="revenus" fill="#8bc34a" />
                                <Bar dataKey="dépenses" fill="#e91e63" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Dashboard>
    )
}

export default AnalysisFiscal

const useStyles = makeStyles(theme => ({
    paper: {
        height: 300
    },
    paperHigh: {
        textAlign: 'center',
        height: 360
    },
    autocomplete: {
        width: 274,
        paddingLeft: theme.spacing(4),
        marginTop: theme.spacing(1),
        display: 'inline-block',
    },
    datePicker: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(1),
        width: 240,
    },
    totalField: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        width: 240,
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(1)
    },
    table: {
        maxWidth: 240,
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(1)
    }
}));

function prepareInputForPie(input) {
    if (input.length <= 5) {
        return input
    }
    const sorted = input.sort((a, b) => (a.total > b.total) ? -1 : 1)
    const top5 = sorted.slice(0, 4)
    top5.push(
        {
            name: 'Autres',
            total: sorted.slice(4, sorted.length).reduce((a, b) => a + b.total, 0)
        })
    return top5
}

