import { makeStyles, Paper } from '@material-ui/core';
import Dashboard from '../components/Dashboard'
import React from 'react'

const Home = () => {
    const classes = useStyles()

    return (
        <Dashboard>
            <Paper className={classes.image}>
                <img height='100%' src={`${process.env.PUBLIC_URL}/logo.jpg`} />
            </Paper>
        </Dashboard >
    )
}

export default Home;


const useStyles = makeStyles(theme => ({
    image: {
        height: '30vh',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    }
}))