import React from 'react';
import { IconButton } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';

export const FileUploadCell = (
  data,
  filePath,
  token,
  to,
  handleFileSubmit,
  handleFileDelete
) => {
  if (data === undefined || data.tableData.editing === 'update') {
    return <div></div>;
  }
  if (filePath.length === 0) {
    return (
      <div>
        <IconButton
          variant='contained'
          component='label'
          onClick={clearInput}
          size={'small'}
        >
          <NoteAddIcon />
          <input
            type='file'
            style={{ display: 'none' }}
            onChange={handleFileSubmit(data.id)}
          />
        </IconButton>
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a
          href={`${process.env.REACT_APP_API_URL}${to}/${data.id}?token=${token}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Télécharger
        </a>
        <IconButton
          onClick={handleFileDelete(data.id)}
          size={'small'}
          style={{ marginLeft: 4 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
};

const clearInput = (event) => {
  event.target.value = '';
};
