import { authHeader, handleResponse } from "../helpers/authentication";

function getAll() {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/notifications/`, requestOptions).then(handleResponse)
}

export default {
    getAll,
};
