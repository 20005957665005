import React, { useState } from 'react';
import { Dialog, makeStyles, DialogTitle, IconButton, DialogContent, DialogActions, Button, TextField, InputLabel, Select, FormControl, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale'
import moment from 'moment';

const MultipleWindowsEditDialog = (props) => {
    const classes = useStyles();

    const { open, handleClose, handleEditMultiple, employees, percentages, title, dataArray } = props

    const handleCloseDialog = () => {
        handleClose()
    }

    const [data, setData] = useState({
        date: null,
        employeeId: 0,
        percentage: 0,
        note: ''
    })

    const handleChange = name => event => {
        var value;
        if (name === 'date') {
            value = moment(event).isValid()
                ? moment(event).format('YYYY-MM-DD')
                : null
        } else {
            value = event.target.value;
        }
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const dataToSend = []
        dataArray.forEach(w => {
            const employeeId = +data.employeeId === 0 ? null : +data.employeeId
            dataToSend.push({
                ...w,
                date: data.date,
                employeeId: employeeId,
                percentage: +data.percentage,
                note: data.note
            })
        });
        setData({
            date: null,
            employeeId: 0,
            percentage: 0,
            note: ''
        })
        handleEditMultiple(dataToSend);
        handleCloseDialog();
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>
                Modifier plusieurs {title}
                <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent className={classes.container}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                        <KeyboardDatePicker
                            margin="normal"
                            label="Date"
                            cancelLabel="Annuler"
                            format="dd.MM.yyyy"
                            value={data.date}
                            className={classes.textField}
                            onChange={handleChange('date')}
                            KeyboardButtonProps={{
                                'aria-label': 'Changer la date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <FormControl margin="normal" className={classes.textField}>
                        <InputLabel>Fournisseur</InputLabel>
                        <Select
                            value={data.employeeId}
                            onChange={handleChange('employeeId')}
                        >
                            {Object.keys(employees).map((key, i) =>
                                <MenuItem key={i} value={key}>{employees[key]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" className={classes.textField}>
                        <InputLabel>%</InputLabel>
                        <Select
                            value={data.percentage}
                            onChange={handleChange('percentage')}
                        >
                            {Object.keys(percentages).map((key, i) =>
                                <MenuItem key={i} value={key}>{percentages[key]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Remarque'
                        defaultValue={data.note}
                        className={classes.textField}
                        onBlur={handleChange('note')}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary">
                        Modifier
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default MultipleWindowsEditDialog;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        textAlign: 'left',
    },
    autocomplete: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: (200 + theme.spacing(1)) * 2,
        textAlign: 'left',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actions: {
        width: '100%'
    },
}));