import React, { useRef, useEffect, useState } from 'react';
import { getColor } from '../../helpers/getColor';
import { select } from 'd3';

const SVGWindowElement = ({
  window,
  text: showText,
  size,
  rx,
  ry,
  strokeWidth,
  setAnchorEl,
  setMenuData,
}) => {
  const textStyle = {
    pointerEvents: 'none',
    fill: 'white',
    stroke: 'black',
    fontWeight: 'bold',
    fontSize: size,
  };
  const rectStyle = {
    fill: getColor(window),
    rx: rx,
    ry: ry,
    strokeWidth: strokeWidth,
  };

  const textRef = useRef();
  const [textSize, setTextSize] = useState();
  useEffect(() => {
    if (textRef.current) {
      setTextSize(select(textRef.current).node().getBBox().width * 1.1);
    }
  }, [showText]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setMenuData(window);
  };

  const employeeInitials = !!window.employee
    ? `${window.employee.firstName[0]}${window.employee.lastName[0]}`
    : '';

  return (
    <g transform={`translate(${window.x}, ${window.y})`} onClick={handleClick}>
      <rect
        width={showText ? textSize : size + 3}
        height={size + 3}
        style={rectStyle}
        stroke='black'
      ></rect>
      <text ref={textRef} x={size * 0.15} y={size * 0.9} style={textStyle}>
        {showText ? `${employeeInitials} - ${window.name}` : employeeInitials}
      </text>
    </g>
  );
};

export default SVGWindowElement;
