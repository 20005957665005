import React from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const FancyFloor = (props) => {
    const { floor } = props

    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        if (floor.imagePath.length === 0) {
            return;
        }
        history.push(`map/${floor.id}`)
    }

    return (
        <div className={classes.container} onClick={handleClick}>
            <div className={classes.title}>
                {floor.name}
            </div>
            <div className={classes.bottom}>
                <div className={classes.percentage}>
                    {`${Number(floor.percentage).toFixed(2)}%`}
                </div>
                <LinearProgress
                    variant="determinate"
                    value={floor.percentage}
                    className={classes.progress}
                />
            </div>
        </div>
    )
}

export default FancyFloor;

const useStyles = makeStyles(theme => ({
    container: {
        padding: '4px 0px',
        width: '100%',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#dcedc8'
        },
    },
    title: {
        textAlign: 'center',
        fontSize: 20
    },
    bottom: {
        width: '100%',
    },
    percentage: {
        minWidth: 64,
        fontSize: 18,
        marginLeft: 8,
        marginRight: 8,
        marginTop: -2,
        float: 'left'
    },
    progress: {
        margin: 4,
        height: 20
    }
}));