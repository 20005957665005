import React, { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import { makeStyles, Paper, Snackbar, TextField, Button } from "@material-ui/core";
import { vehicleService } from '../services/vehicleService';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import CustomSnackbarContent from '../components/CustomSnackbarContent';
import Files from '../components/Files';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale'

const VehicleDetails = () => {
    const classes = useStyles();
    const { id } = useParams();

    const handleChange = name => (event) => {
        var value;
        if (name === 'registrationDate') {
            value = moment(event).isValid()
                ? moment(event).format('YYYY-MM-DD')
                : null
        } else {
            value = event.target.value;
        }
        setVehicle({ ...vehicle, [name]: value });
    };

    const errorSnackbar = (err) => {
        if (typeof err === 'string' || err instanceof String) {
            setVariant("error");
            setMessage(err);
            setOpenSnackbar(true);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        vehicleService.update(vehicle)
            .then(() => {
                getVehicle(id);
                setVariant("success");
                setMessage("Enregistrement réussi");
                setOpenSnackbar(true);
            })
            .catch(err => {
                errorSnackbar(err)
            })
    }

    const [vehicle, setVehicle] = useState({
        name: '',
        registration: '',
        driver: '',
        registrationDate: null
    })

    const getVehicle = (id) => {
        vehicleService.get(id)
            .then(res => {
                setVehicle({ ...res })
            })
            .catch(err => {
                errorSnackbar(err)
            })
    }

    useEffect(() => {
        getVehicle(id)
        getFiles(id)
    }, [id]);

    const [files, setFiles] = useState([])
    const getFiles = id => {
        setFileLoading(true)
        vehicleService.getFiles(id)
            .then(res => {
                setFiles(res)
                setFileLoading(false)
            })
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false)
            })
    }

    const uploadFile = id => formData => {
        if (formData.get('file') === 'undefined') {
            return
        }
        setFileLoading(true)
        vehicleService.uploadFile(id, formData)
            .then(() => {
                getFiles(id)
            })
            .catch(err => {                
                errorSnackbar(err)
                setOpenSnackbar(true);
            })
    }

    const updateFile = (fileId, file) => {
        vehicleService.updateFile(fileId, file)
            .then(() => getFiles(id))
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false);
            })
    }

    const [deleteId, setDeleteId] = useState(0)
    const deleteFile = () => {
        setConfirmOpen(false)
        vehicleService.deleteFile(deleteId)
            .then(() => {
                getFiles(id)
            })
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false)                
            })
    }

    const [variant, setVariant] = useState('success');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }

    const [fileLoading, setFileLoading] = useState(false)

    return (
        <Dashboard title={vehicle.name}>
            <Paper className={classes.paper}>
                <form autoComplete="off" onSubmit={handleSubmit} className={classes.container}>
                    <TextField
                        label='Nom'
                        required
                        value={vehicle.name}
                        className={classes.textField}
                        onChange={handleChange('name')}
                        margin="normal"
                    />
                    <TextField
                        label='Immatriculation'
                        value={vehicle.registration}
                        className={classes.textField}
                        onChange={handleChange('registration')}
                        margin="normal"
                    />
                    <TextField
                        label='Chauffeur'
                        value={vehicle.driver}
                        className={classes.textField}
                        onChange={handleChange('driver')}
                        margin="normal"
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                        <KeyboardDatePicker
                            margin="normal"
                            label='Controle technique'
                            cancelLabel="Annuler"
                            format="dd.MM.yyyy"
                            value={vehicle.registrationDate}
                            className={classes.textField}
                            onChange={handleChange('registrationDate')}
                            KeyboardButtonProps={{
                                'aria-label': 'Changer la date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div className={classes.actions}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={() => getVehicle(id)}>
                            Annuler
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </Paper>
            <Files
                files={files}
                uploadFile={uploadFile(id)}
                deleteFile={(id) => {
                    setConfirmOpen(true);
                    setDeleteId(id);
                }}
                to={'api/Vehicles/Files'}
                isLoading={fileLoading}
                updateFile={updateFile}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <CustomSnackbarContent
                    onClose={handleCloseSnackbar}
                    variant={variant}
                    className={classes.snackbar}
                    message={message}
                />
            </Snackbar>
            <ConfirmDeleteDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleDelete={deleteFile}
                text={'Confirmer la suppression de fichier?'}
            />
        </Dashboard>
    )
}

export default VehicleDetails;


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        marginBottom: theme.spacing(1)
    },
    container: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        textAlign: 'left',
    },
    remarksField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 600 + theme.spacing(5),
        textAlign: 'left',
    },
    actions: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        textAlign: 'right',
    },
    snackbar: {
        margin: theme.spacing(1),
    },
    button: {
        marginRight: '8px'
    }
}));