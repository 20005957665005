import { authHeader, handleResponse } from '../helpers/authentication'

export const vehicleService = {
    get,
    getAll,
    create,
    update,
    deleteVehicle,
    getFiles,
    uploadFile,
    deleteFile,
    updateFile
}

function get(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/${id}`, requestOptions).then(handleResponse)     
}

function getAll() {   
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles`, requestOptions).then(handleResponse)     
}

function create(vehicle) {
    const requestOptions =
    {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(vehicle)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles`, requestOptions).then(handleResponse)        
}

function update(vehicle) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(vehicle)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/${vehicle.id}`, requestOptions).then(handleResponse)        
}

function deleteVehicle(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: {
            ...authHeader(),            
        },
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/${id}`, requestOptions).then(handleResponse)        
}

function getFiles(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/${id}/Files`, requestOptions).then(handleResponse)     
}

function uploadFile(id, formData) {
    formData.append("vehicleId", id);
    const requestOptions =
    {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/Files`, requestOptions).then(handleResponse)     
}

function deleteFile(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/Files/${id}`, requestOptions).then(handleResponse)     
}

function updateFile(id, file) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(file)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Vehicles/Files/${id}`, requestOptions).then(handleResponse)
}