import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../components/Dashboard'
import { reserveService } from '../services/reserveService'
import { ReserveStatus } from '../helpers/reserveStatus'
import { fr } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import {
    makeStyles, Paper, Snackbar, TextField,
    InputLabel, FormControl, Select, MenuItem, Button
} from '@material-ui/core'
import CustomSnackbarContent from '../components/CustomSnackbarContent'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { ReserveStatusFR, ReserveStatusList } from '../helpers/reserveStatus'
import Files from '../components/Files'
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog'


const ReserveDetails = () => {
    const { id } = useParams()

    const [reserve, setReserve] = useState({
        status: ReserveStatus.InProgress,
        date: null,
        jobName: '',
        address: '',
        location: '',
        contactInfo: '',
        note: ''
    })

    const handleChange = name => (event) => {
        var value;
        if (name === 'date') {
            value = moment(event).isValid()
                ? moment(event).format('YYYY-MM-DD')
                : null
        } else {
            value = event.target.value;
        }
        setReserve({ ...reserve, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        reserveService.update(reserve)
            .then(() => {
                getReserve(id);
                setVariant("success");
                setMessage("Enregistrement réussi");
                setOpenSnackbar(true);
            })
            .catch(err => {
                errorSnackbar(err)
            })
    }

    const [variant, setVariant] = useState('success');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const errorSnackbar = (err) => {
        if (typeof err === 'string' || err instanceof String) {
            setVariant("error");
            setMessage(err);
            setOpenSnackbar(true);
        }
    }

    const getReserve = (id) => {
        reserveService.get(id)
            .then(res => {
                setReserve({ ...res })
            })
            .catch(err => {
                errorSnackbar(err)
            })
    }

    useEffect(() => {
        getReserve(id)
        getFiles(id)
    }, [id]);

    const [fileLoading, setFileLoading] = useState(false)
    const [files, setFiles] = useState([])
    const getFiles = id => {
        setFileLoading(true)
        reserveService.getFiles(id)
            .then(res => {
                setFiles(res)
                setFileLoading(false)
            })
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false)
            })
    }

    const uploadFile = id => formData => {
        if (formData.get('file') === 'undefined') {
            return
        }
        setFileLoading(true)
        reserveService.uploadFile(id, formData)
            .then(() => {
                getFiles(id)
            })
            .catch(err => {
                errorSnackbar(err)
                setOpenSnackbar(true);
            })
    }

    const updateFile = (fileId, file) => {
        reserveService.updateFile(fileId, file)
            .then(() => getFiles(id))
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false);
            })
    }

    const [deleteId, setDeleteId] = useState(0)
    const deleteFile = () => {
        setConfirmOpen(false)
        reserveService.deleteFile(deleteId)
            .then(() => {
                getFiles(id)
            })
            .catch(err => {
                errorSnackbar(err)
                setFileLoading(false)
            })
    }

    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }

    const classes = useStyles()

    return (
        <Dashboard title={`Reserve - ${reserve.jobName}`}>
            <Paper className={classes.paper}>
                <form autoComplete="off" onSubmit={handleSubmit} className={classes.container}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                        <KeyboardDatePicker
                            required
                            margin="normal"
                            label="Date de demande"
                            cancelLabel="Annuler"
                            format="dd.MM.yyyy"
                            value={reserve.date}
                            className={classes.textFieldLong}
                            onChange={handleChange('date')}
                            KeyboardButtonProps={{
                                'aria-label': 'Changer la date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        label='Chantier'
                        required
                        value={reserve.jobName}
                        className={classes.textFieldLong}
                        onChange={handleChange('jobName')}
                        margin="normal"
                    />
                    <TextField
                        label='Adresse'
                        value={reserve.address}
                        className={classes.textFieldLong}
                        onChange={handleChange('address')}
                        margin="normal"
                    />
                    <FormControl margin="normal" className={classes.textFieldLong}>
                        <InputLabel>Statut</InputLabel>
                        <Select
                            value={reserve.status}
                            onChange={handleChange('status')}
                        >
                            {ReserveStatusList.map((status, i) =>
                                <MenuItem key={i} value={status}>{ReserveStatusFR[status]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Localisation'
                        value={reserve.location}
                        className={classes.textFieldLong}
                        onChange={handleChange('location')}
                        margin="normal"
                    />
                    <div className={classes.textFieldLong}></div>
                    <TextField
                        label='Nom et numéro conducteur'
                        value={reserve.contactInfo}
                        className={classes.textFieldLong}
                        onChange={handleChange('contactInfo')}
                        multiline
                        rowsMax={6}
                        rows={6}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label='Remarque'
                        value={reserve.note}
                        className={classes.textFieldLong}
                        onChange={handleChange('note')}
                        multiline
                        rowsMax={6}
                        rows={6}
                        margin="normal"
                        variant="outlined"
                    />
                    <div className={classes.textFieldLong}></div>
                    <div className={classes.actions}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={() => getReserve(id)}>
                            Annuler
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <CustomSnackbarContent
                    onClose={handleCloseSnackbar}
                    variant={variant}
                    className={classes.snackbar}
                    message={message}
                />
            </Snackbar>
            <Files
                files={files}
                uploadFile={uploadFile(id)}
                deleteFile={(id) => {
                    setConfirmOpen(true);
                    setDeleteId(id);
                }}
                to={'api/Reserves/Files'}
                isLoading={fileLoading}
                updateFile={updateFile}
            />
            <ConfirmDeleteDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleDelete={deleteFile}
                text={'Confirmer la suppression de reserve?'}
            />
        </Dashboard>
    )
}

export default ReserveDetails

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        marginBottom: theme.spacing(1)
    },
    container: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textFieldShort: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 220,
        textAlign: 'left',
    },
    textFieldLong: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 320,
        textAlign: 'left',
    },
    remarksField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 600 + theme.spacing(5),
        textAlign: 'left',
    },
    actions: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        textAlign: 'right',
    },
    snackbar: {
        margin: theme.spacing(1),
    },
    button: {
        marginRight: '8px'
    }
}));