import React, { useState } from 'react';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography, Paper, LinearProgress } from '@material-ui/core';
import FancyFloor from './FancyFloor';
import { floorService } from '../../services/floorService';
import BuildingIcon from '../../assets/building.png';

const FancyBuilding = (props) => {

    const { building } = props

    const classes = useStyles()

    const [floors, setFloors] = useState([])

    const getFloors = (buildingId) => {
        floorService.getAll(buildingId)
            .then(res => setFloors(res))
    }

    const handleChange = (event, expanded) => {
        if (expanded) {
            getFloors(building.id)
        }
    }

    return (
        <Paper className={classes.container}>
            <Accordion onChange={handleChange}>
                <AccordionSummary className={classes.accordionSummary}>
                    <div className={classes.title} >
                        <Typography variant="h5">
                            {building.name}
                        </Typography>
                        <img src={BuildingIcon} alt='' className={classes.image} />
                        <div>
                            <div className={classes.percentage}>
                                {`${Number(building.percentage).toFixed(2)}%`}
                            </div>
                            <LinearProgress
                                variant="determinate"
                                value={building.percentage}
                                className={classes.progress}
                            />
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {floors.map(floor =>
                        <FancyFloor
                            key={floor.id}
                            floor={floor}
                        />)}
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}

export default FancyBuilding;

const useStyles = makeStyles(theme => ({
    image: {
        opacity: 0.75,
        width: 220,
    },
    container: {
        maxWidth: 240,
        minWidth: 240,
        margin: theme.spacing(1),
        height: '100%',
    },
    accordionSummary: {
        padding: 0,
        margin: '2px 4px'
    },
    accordionDetails: {
        padding: 0,
        margin: '2px 4px',
        display: 'block',
        maxHeight: 300,
        overflowY: 'auto',
    },
    title: {
        margin: 'auto',
        width: 230,
        textAlign: 'center'
    },
    percentage: {
        minWidth: 64,
        fontSize: 18,
        marginLeft: 8,
        marginRight: 8,
        marginTop: -2,
        float: 'left'
    },
    progress: {
        margin: 4,
        height: 20,
    },
}));