import { drag, select, zoom, zoomIdentity, zoomTransform, event } from 'd3';
import React, { useRef, useEffect, useState } from 'react';
import SVGLegend from './SVGLegend';
import SVGWindowElement from './SVGWindowElement';

const SVGComponent = ({ data, width, height, imagePath, editing, mark, text, setAnchorEl, setMenuData }) => {
  const svgRef = useRef();
  const [transform, setTransform] = useState(zoomIdentity);

  const svgWidth = 900;
  const svgHeight = 600;
  const SCALE = width / 1200;
  const SIZE = width / 40;
  const RX_SCALE = width / 150;
  const RY_SCALE = height / 150;
  const STROKE_WIDTH = width / 750;

  useEffect(() => {
    let svg = select(svgRef.current);
    const zoomBehaviour = zoom()
      .scaleExtent([svgWidth / width, 1.5])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on('zoom', () => {
        setTransform(zoomTransform(svg.node()));
      });
    svg.call(zoomBehaviour);

    // start all zoomed out
    zoomBehaviour.scaleBy(svg, svgWidth / width);

    select('#zoom_out').on('click', function () {
      zoomBehaviour.scaleBy(svg, 0.75);
    });
    select('#zoom_in').on('click', function () {
      zoomBehaviour.scaleBy(svg, 1.25);
    });
  }, [width, height]);

  useEffect(() => {
    if (!mark || !editing) {
      return;
    }
    let rects = select(svgRef.current)
      .select('g')
      .selectAll('g')
      .filter(function () {
        return select(this).selectAll('rect').size() === 1;
      })
      .data(data);

    const dragBehaviour = drag()
      .on('start', function () {
        select(this).select('rect').attr('stroke', '#8bc34a');
      })
      .on('drag', function (element) {
        element.x = event.x;
        element.y = event.y;
        element.x = Math.max(element.x, 0);
        element.y = Math.max(element.y, 0);
        element.x = Math.min(element.x, width - SIZE);
        element.y = Math.min(element.y, height - SIZE);
        select(this).attr('transform', `translate(${element.x},${element.y})`);
      })
      .on('end', function () {
        select(this).select('rect').attr('stroke', 'black');
      });

    rects.call(dragBehaviour);
  }, [data, mark, editing, SIZE, height, width]);

  return (
    <svg
      id='svg'
      style={{ border: '2px solid black' }}
      height={svgHeight}
      width={svgWidth}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      ref={svgRef}
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g transform={`translate(${transform.x},${transform.y}) scale(${transform.k})`}>
        <image xlinkHref={imagePath} width={width} height={height} />
        {mark && (
          <g transform={`translate(10,10) scale(${SCALE})`}>
            <SVGLegend />
          </g>
        )}
        {mark &&
          data.map((el) => (
            <SVGWindowElement
              key={el.id}
              window={el}
              text={text}
              scale={SCALE}
              size={SIZE}
              rx={RX_SCALE}
              ry={RY_SCALE}
              strokeWidth={STROKE_WIDTH}
              setAnchorEl={setAnchorEl}
              setMenuData={setMenuData}
            />
          ))}
      </g>
    </svg>
  );
};
export default SVGComponent;
