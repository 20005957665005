import { authHeader, handleResponse } from '../helpers/authentication'

export const reserveService = {
    get,
    getAll,
    create,
    update,
    deleteReserve,
    getFiles,
    uploadFile,
    deleteFile,
    updateFile
}

function get(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/${id}`, requestOptions).then(handleResponse)
}

function getAll(params) {
    var paramArray = params === undefined ? [] : params
    var paramString = '?'
    paramArray.forEach(p => paramString = (p.value !== null && p.value !== undefined) ? paramString.concat(`${p.name}=${p.value}&`) : paramString);
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves${paramString}`, requestOptions).then(handleResponse)
}

function create(reserve) {
    const requestOptions =
    {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reserve)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves`, requestOptions).then(handleResponse)
}

function update(reserve) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reserve)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/${reserve.id}`, requestOptions).then(handleResponse)
}

function deleteReserve(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: {
            ...authHeader(),
        },
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/${id}`, requestOptions).then(handleResponse)
}

function getFiles(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/${id}/Files`, requestOptions).then(handleResponse)     
}

function uploadFile(id, formData) {
    formData.append("reserveId", id);
    const requestOptions =
    {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/Files`, requestOptions).then(handleResponse)     
}

function deleteFile(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/Files/${id}`, requestOptions).then(handleResponse)     
}

function updateFile(id, file) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(file)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Reserves/Files/${id}`, requestOptions).then(handleResponse)
}