import { authHeader, handleResponse } from '../helpers/authentication';

export const windowService = {
  get,
  getAll,
  create,
  createMultiple,
  updateMultiple,
  update,
  deleteWindow,
  updatePositions,
};

function get(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/${id}`, requestOptions).then(
    handleResponse
  );
}

function getAll(floorId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/Windows/byFloor/${floorId}`,
    requestOptions
  ).then(handleResponse);
}

function create(window) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(window),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows`, requestOptions).then(handleResponse);
}

function createMultiple(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/Multiple`, requestOptions).then(
    handleResponse
  );
}

function updateMultiple(data) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/Multiple`, requestOptions).then(
    handleResponse
  );
}

function update(window) {
  window.employeeId = window.employeeId ? window.employeeId : null;
  const requestOptions = {
    method: 'PUT',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(window),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/${window.id}`, requestOptions).then(
    handleResponse
  );
}

function deleteWindow(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/${id}`, requestOptions).then(
    handleResponse
  );
}

function updatePositions(windows) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(windows),
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/Windows/UpdatePositions`, requestOptions).then(
    handleResponse
  );
}
