import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { fr } from 'date-fns/locale'
import moment from 'moment'
import { Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, makeStyles, IconButton, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { ReserveStatusFR, ReserveStatusList } from '../helpers/reserveStatus'
import Autocomplete from '@material-ui/lab/Autocomplete'


const ReserveAddDialog = ({ jobs, open, handleClose, handleAdd, reserveToAdd, setReserveToAdd }) => {
    const classes = useStyles();

    const handleChange = name => event => {
        var value;
        if (name === 'date') {
            value = moment(event).isValid()
                ? moment(event).format('YYYY-MM-DD')
                : null
        } else {
            value = event.target.value;
        }
        setReserveToAdd({ ...reserveToAdd, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAdd();
    }

    const handleSuggest = (e, job) => {
        if (!job) {
            return
        }
        setReserveToAdd({
            ...reserveToAdd,
            address: job.address,
            jobName: `${job.name} (${job.clientName})`
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                Ajouter de reserve
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent className={classes.container} >
                    <Autocomplete
                        className={classes.autocomplete}
                        options={jobs}
                        getOptionLabel={job => job.clientName === '' ? '' : `${job.name} (${job.clientName})`}
                        onChange={handleSuggest}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoFocus
                                variant="standard"
                                label={`Suggérer un travail`}
                                margin="dense"
                                fullWidth
                            />
                        )}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                        <KeyboardDatePicker
                            required
                            margin="normal"
                            label="Date de demande"
                            cancelLabel="Annuler"
                            format="dd.MM.yyyy"
                            value={reserveToAdd.date}
                            className={classes.textFieldShort}
                            onChange={handleChange('date')}
                            KeyboardButtonProps={{
                                'aria-label': 'Changer la date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        label='Chantier'
                        required
                        value={reserveToAdd.jobName}
                        className={classes.textFieldLong}
                        onChange={handleChange('jobName')}
                        margin="normal"
                    />
                    <FormControl margin="normal" className={classes.textFieldShort}>
                        <InputLabel>Statut</InputLabel>
                        <Select
                            value={reserveToAdd.status}
                            onChange={handleChange('status')}
                        >
                            {ReserveStatusList.map((status, i) =>
                                <MenuItem key={i} value={status}>{ReserveStatusFR[status]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Adresse'
                        value={reserveToAdd.address}
                        className={classes.textFieldLong}
                        onChange={handleChange('address')}
                        margin="normal"
                    />
                    <TextField
                        label='Localisation'
                        value={reserveToAdd.location}
                        className={classes.textFieldShort}
                        onChange={handleChange('location')}
                        margin="normal"
                    />
                    <TextField
                        label='Nom et numéro conducteur'
                        value={reserveToAdd.contactInfo}
                        className={classes.textFieldLong}
                        onChange={handleChange('contactInfo')}
                        multiline
                        rowsMax={4}
                        rows={4}
                        margin="normal"
                        variant="outlined"
                    />
                    <div className={classes.textFieldShort}></div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ReserveAddDialog

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    autocomplete: {
        marginTop: 11,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        textAlign: 'left',
    },
    textFieldLong: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        textAlign: 'left',
    },
    textFieldShort: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        textAlign: 'left',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actions: {
        width: '100%'
    }
}));