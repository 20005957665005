import React, { useState } from 'react';
import MaterialTable, { MTableCell } from 'material-table';
import { Localization } from '../../helpers/localization';
import moment from 'moment';
import { windowService } from '../../services/windowService';
import MultipleWindowsEditDialog from '../../dialogs/MultipleWindowsEditDialog';

export const percentages = {
    0: '0%',
    80: '80%',
    90: '90%',
    100: '100%',
    500: 'TS'
}

const Windows = (props) => {
    const { data, loading, title, employees, getWindows, floorId, setOpen, updatePercentages, setSnackbar } = props

    const columns = [
        { title: 'Nom', field: 'name' },
        {
            title: 'Date',
            field: 'date',
            type: 'date',
            render: rowData => rowData.date && <div>{moment(rowData.date).format('DD.MM.YYYY')}</div>,
            initialEditValue: moment()
        },
        {
            title: 'Fournisseur',
            field: 'employeeId',
            lookup: employees,
            initialEditValue: 0
        },
        {
            title: '% (ou TS)',
            field: 'percentage',
            lookup: percentages,
            initialEditValue: 0,
        },
        { title: 'Remarque', field: 'note' }
    ]

    const options = {
        selection: true,
        search: false,
        paging: false,
        maxBodyHeight: 320,
        minBodyHeight: 320,
        draggable: false,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
    }

    const [openEdit, setOpenEdit] = useState(false)
    const [dataToEdit, setDataToEdit] = useState([])
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const editMultipleWindows = (dataArray) => {
        windowService.updateMultiple(dataArray)
            .then(() => {
                updatePercentages()
                getWindows(floorId)
            })
    }


    const actions = floorId ?
        [
            {
                icon: 'library_add',
                tooltip: 'Ajouter plusieurs',
                onClick: (event, rowData) => {
                    setOpen(true);
                },
                isFreeAction: true
            },
            {
                tooltip: 'Modifier plusieurs',
                icon: 'edit',
                onClick: (evt, data) => {
                    setDataToEdit(data);
                    setOpenEdit(true)
                }
            }
        ] : null

    const editable = floorId ?
        {
            onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    const employeeId = +newData.employeeId === 0 ? null : +newData.employeeId
                    const date = moment(newData.date).isValid() ? moment(newData.date).format('YYYY-MM-DD') : null
                    const item = {
                        name: newData.name,
                        date: date,
                        percentage: +newData.percentage,
                        employeeId: employeeId,
                        note: newData.note,
                        floorId: +floorId
                    }
                    windowService.create(item)
                        .then(() => {
                            updatePercentages()
                            getWindows(floorId)
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Entrée invalide'
                            })
                            reject()
                        })
                }),
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    const employeeId = +newData.employeeId === 0 ? null : +newData.employeeId
                    const date = moment(newData.date).isValid() ? moment(newData.date).format('YYYY-MM-DD') : null
                    const item = {
                        id: oldData.id,
                        name: newData.name,
                        date: date,
                        percentage: +newData.percentage,
                        employeeId: employeeId,
                        note: newData.note,
                        floorId: +floorId
                    }
                    windowService.update(item)
                        .then(() => {
                            updatePercentages()
                            getWindows(floorId)
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Entrée invalide'
                            })
                            reject()
                        })
                }),
            onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    windowService.deleteWindow(oldData.id)
                        .then(() => {
                            updatePercentages()
                            getWindows(floorId)
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Erreur'
                            })
                            reject()
                        })
                }),
        } : null

    return (
        <div>
            <MaterialTable
                columns={columns}
                options={options}
                editable={editable}
                actions={actions}
                data={data}
                dense={true}
                title={`Fenêtres ${title}`}
                localization={Localization}
                isLoading={loading}
                components={{
                    Cell: props => <MTableCell {...props} style={{ padding: '0px 16px' }} />,
                }}
            />
            <MultipleWindowsEditDialog
                open={openEdit}
                handleClose={handleCloseEdit}
                handleEditMultiple={editMultipleWindows}
                employees={employees}
                percentages={percentages}
                title={title}
                dataArray={dataToEdit}
            />
        </div>


    )
}

export default Windows;