import React, { useState, useEffect } from 'react'
import Dashboard from '../components/Dashboard';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MaterialTable, { MTableCell } from 'material-table';
import { Localization } from '../helpers/localization';
import { vehicleService } from '../services/vehicleService';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import VehicleAddDialog from '../dialogs/VehicleAddDialog';
import { getTableBodyHeights } from '../helpers/table';

const Vehicles = () => {
    const history = useHistory()
    const columns = [
        { title: 'Nom', field: 'name', cellStyle: { paddingLeft: 16 } },
        { title: 'Immatriculation', field: 'registration' },
        { title: 'Chauffeur', field: 'driver' },
        {
            title: 'Controle technique',
            field: 'registrationDate',
            type: 'date',
            dateSetting: { locale: 'fr-FR' },
            render: rowData => { return rowData.registrationDate && <div>{moment(rowData.registrationDate).format('DD.MM.YYYY')}</div> }
        },
    ];
    const options = {
        draggable: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 25, 50],
        paging: true,
        pageSize: 10,
        ...getTableBodyHeights(),
        rowStyle: (rowData) => {
            if (moment(rowData.registrationDate).isValid() &&
                moment(new Date()).isAfter(moment(rowData.registrationDate).subtract(1, 'months'))) {
                return {
                    backgroundColor: '#FFA726',
                }
            }
        }
    }
    const actions = [
        {
            icon: 'add_box',
            tooltip: 'Ajouter',
            isFreeAction: true,
            onClick: (event) => {
                setOpenAdd(true);
            }
        },
        rowData => ({
            icon: 'more_horiz',
            tooltip: 'Détails',
            onClick: (event, rowData) => {
                history.push(String(rowData.id))
            }
        }),
        rowData => ({
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
                setDeleteId(rowData.id);
                setConfirmOpen(true);
            }
        }),
    ]

    const [vehicleToAdd, setVehicleToAdd] = useState({
        name: '',
        registration: '',
        driver: '',
        registrationDate: null
    })
    const handleAddVehicle = () => {
        vehicleService.create(vehicleToAdd)
            .then(() => {
                handleCloseAdd();
                getVehicles();
            })
    }

    const [openAdd, setOpenAdd] = useState(false)
    const handleCloseAdd = () => {
        setOpenAdd(false);
        setVehicleToAdd({
            name: '',
            registration: '',
            driver: '',
            registrationDate: null
        });
    }

    const [deleteId, setDeleteId] = useState(0)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }
    const handleDeleteVehicle = () => {
        vehicleService.deleteVehicle(deleteId)
            .then(() => {
                handleConfirmClose()
                getVehicles()
            })
    }

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getVehicles = () => {
        vehicleService.getAll()
            .then(res => {
                setData(res)
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        getVehicles()
    }, [])

    return (
        <Dashboard title={'Véhicules'}>
            <MaterialTable
                columns={columns}
                data={data}
                options={options}
                actions={actions}
                title="Véhicules"
                isLoading={loading}
                localization={Localization}
                components={{
                    Cell: props => <MTableCell {...props} style={{ padding: '0px 4px' }} />
                }}
            />
            <VehicleAddDialog
                open={openAdd}
                handleClose={handleCloseAdd}
                handleAdd={handleAddVehicle}
                vehicleToAdd={vehicleToAdd}
                setVehicleToAdd={setVehicleToAdd}
            />
            <ConfirmDeleteDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleDelete={handleDeleteVehicle}
                text={'Confirmer la suppression de véhicule?'}
            />
        </Dashboard>
    )
}

export default Vehicles;