import React from 'react';
import { Route } from 'react-router-dom';
import Login from './Login';
import AnalysisPlanning from '../components/Analysis/AnalysisPlanning';
import AnalysisMonitoring from '../components/Analysis/AnalysisMonitoring';
import { PrivateRoute } from './PrivateRoute';
import Users from './Users';
import Jobs from './Jobs';
import Employees from './Employees';
import EmployeeDetails from './EmployeeDetails';
import JobDetails from './JobDetails';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { lightGreen } from '@material-ui/core/colors';
import Schedules from './Schedules';
import { frFR } from '@material-ui/core/locale';
import Vehicles from './Vehicles';
import VehicleDetails from './VehicleDetails';
import Monitoring from '../components/Monitoring/Monitoring';
import { Role } from '../helpers/role';
import AnalysisFiscal from '../components/Analysis/AnalysisFiscal';
import Reserves from './Reserves';
import ReserveDetails from './ReserveDetails';
import Home from './Home';
import AccountingBuy from './AccountingBuy';
import { QueryClient, QueryClientProvider } from 'react-query';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: lightGreen[500], contrastText: '#fff' },
    },
  },
  frFR
);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div>
          <Route path='/login' component={Login} />
          <PrivateRoute
            exact
            path='/planning/:date?'
            component={Schedules}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/travaux/'
            component={Jobs}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/travaux/:id'
            component={JobDetails}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/reserves/'
            component={Reserves}
            roles={[Role.Admin, Role.Manager, Role.Reserves]}
          />
          <PrivateRoute
            exact
            path='/reserves/:id'
            component={ReserveDetails}
            roles={[Role.Admin, Role.Manager, Role.Reserves]}
          />
          <PrivateRoute
            exact
            path='/personnel/'
            component={Employees}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/contable/achat/'
            component={AccountingBuy}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/personnel/:id'
            component={EmployeeDetails}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/vehicules/'
            component={Vehicles}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/vehicules/:id'
            component={VehicleDetails}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/utilisateurs/'
            component={Users}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path='/surveillance/:jobId/'
            component={Monitoring}
            roles={[Role.Admin, Role.Manager, Role.User]}
          />
          <PrivateRoute
            exact
            path='/analyse/pose/'
            component={AnalysisMonitoring}
            roles={[Role.Admin, Role.Manager, Role.User]}
          />
          <PrivateRoute
            exact
            path='/analyse/planning/'
            component={AnalysisPlanning}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/analyse/chantier/'
            component={AnalysisFiscal}
            roles={[Role.Admin, Role.Manager]}
          />
          <PrivateRoute
            exact
            path='/'
            component={Home}
            roles={[Role.Admin, Role.Manager, Role.Reserves, Role.User]}
          />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
