import React from 'react';
import MaterialTable, { MTableCell } from 'material-table';
import { Localization } from '../../helpers/localization';
import { buildingService } from '../../services/buildingService';
import { useHistory } from 'react-router-dom';

const Buildings = (props) => {
    const { data, loading, getBuildings, buildingId, jobId, setSnackbar } = props

    const columns = [
        { title: 'Nom', field: 'name' },
        {
            title: '%',
            field: 'percentage',
            editable: 'never',
            render: rowData => rowData && <div>{`${Number(rowData.percentage).toFixed(2)}%`}</div>
        }
    ]

    const options = {
        search: false,
        paging: false,
        maxBodyHeight: 200,
        minBodyHeight: 200,
        draggable: false,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        rowStyle: (rowData) => {
            if (rowData.id === buildingId) {
                return {
                    backgroundColor: '#bcbcbc',
                }
            }
        }
    }

    const editable = {
        onRowAdd: newData =>
            new Promise((resolve, reject) => {
                const item = {
                    name: newData.name,
                    percentage: 0,
                    jobId: +jobId
                }
                buildingService.create(item)
                    .then(() => {
                        getBuildings(jobId)
                        resolve()
                    })
                    .catch(() => {
                        setSnackbar({
                            open: true,
                            variant: 'error',
                            message: 'Entrée invalide'
                        })
                        reject()
                    })
            }),
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                const item = {
                    id: oldData.id,
                    name: newData.name,
                    percentage: oldData.percentage,
                    jobId: oldData.jobId
                }
                buildingService.update(item)
                    .then(() => {
                        getBuildings(jobId)
                        resolve()
                    })
                    .catch(() => {
                        setSnackbar({
                            open: true,
                            variant: 'error',
                            message: 'Entrée invalide'
                        })
                        reject()
                    })
            }),
        onRowDelete: oldData =>
            new Promise((resolve, reject) => {
                buildingService.deleteBuilding(oldData.id)
                    .then(() => {
                        getBuildings(jobId)                  
                        handleRowDelete()
                        resolve()
                    })
                    .catch(() => {
                        setSnackbar({
                            open: true,
                            variant: 'error',
                            message: 'Erreur'
                        })
                        reject()
                    })
            }),
    }

    const history = useHistory()

    const handleRowClick = (_, rowData) => {
        history.replace(`/surveillance/${jobId}/admin/${rowData.id}`)
    }
    const handleRowDelete = () => {
        history.replace(`/surveillance/${jobId}/admin`)
    }

    return (
        <MaterialTable
            columns={columns}
            options={options}
            editable={editable}
            data={data}
            dense={true}
            title="Bâtiments"
            localization={Localization}
            isLoading={loading}
            components={{
                Cell: props => <MTableCell {...props} style={{ padding: '0px 16px' }} />,
            }}
            onRowClick={handleRowClick}
        />
    )
}

export default Buildings;