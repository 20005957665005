import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  IconButton,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import {
  ScheduleDialogType,
  ScheduleType,
  ScheduleTypeList,
  ScheduleTypeFR,
} from '../helpers/scheduleType';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: 'left',
  },
  autocomplete: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: (200 + theme.spacing(1)) * 2,
    textAlign: 'left',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    width: '100%',
  },
}));

function ScheduleAddDialog({
  open,
  handleClose: onClose,
  jobs,
  employees,
  handleCreate,
  type = ScheduleDialogType.Regular,
}) {
  const classes = useStyles();

  const handleChange = (name) => (event, newValue) => {
    var value;
    if (name === 'date') {
      value = moment(event).format('YYYY-MM-DD');
    } else if (name === 'employees') {
      value = newValue;
    } else if (name === 'job') {
      value = newValue;
    } else {
      value = event.target.value;
    }
    setSchedule({ ...schedule, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const scheduleToCreate = { ...schedule };
    if (type === ScheduleDialogType.Regular) {
      scheduleToCreate.type = ScheduleType.Regular;
    } else if (type === ScheduleDialogType.Leave) {
      delete scheduleToCreate.job;
    }
    handleCreate(scheduleToCreate);
    onClose();
  };

  const [schedule, setSchedule] = useState({
    date: null,
    job: { id: 0, name: '', clientName: '' },
    employees: [],
    type: ScheduleType.NoWork,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {type === ScheduleDialogType.Regular
          ? 'Ajouter en planning'
          : 'Ajouter en congé'}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form autoComplete='off' onSubmit={handleSubmit}>
        <DialogContent className={classes.container}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
            <KeyboardDatePicker
              required={true}
              margin='normal'
              label='Date'
              cancelLabel='Annuler'
              format='dd.MM.yyyy'
              className={classes.textField}
              onChange={handleChange('date')}
              value={schedule.date}
              shouldDisableDate={(date) => date.getDay() === 0}
              KeyboardButtonProps={{
                'aria-label': 'Changer la date',
              }}
            />
          </MuiPickersUtilsProvider>
          {type === ScheduleDialogType.Regular && (
            <Autocomplete
              className={classes.textField}
              options={jobs}
              getOptionLabel={(job) =>
                job.id === 0 ? '' : `${job.name} (${job.clientName})`
              }
              onChange={handleChange('job')}
              value={schedule.job}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Travail'
                  margin='normal'
                  fullWidth
                />
              )}
            />
          )}
          {type === ScheduleDialogType.Leave && (
            <FormControl margin='normal' className={classes.textField}>
              <InputLabel>Type</InputLabel>
              <Select value={schedule.type} onChange={handleChange('type')}>
                {ScheduleTypeList.map((type) => (
                  <MenuItem key={type} value={type}>
                    {ScheduleTypeFR[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Autocomplete
            className={classes.autocomplete}
            multiple
            options={employees}
            getOptionLabel={(employee) =>
              `${employee.firstName} ${employee.lastName}`
            }
            onChange={handleChange('employees')}
            value={schedule.employees}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Personnel'
                margin='normal'
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant='contained' type='submit' color='primary'>
            Ajouter
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ScheduleAddDialog;
