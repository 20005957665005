import React from "react";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import WarningIcon from "@material-ui/icons/Warning";
import PeopleIcon from "@material-ui/icons/People";
import EuroIcon from '@material-ui/icons/Euro';

const Notification = ({ notification }) => {
  const menuStyle = {
    minWidth: 240,
    maxWidth: 560,
    whiteSpace: "normal",
    display: "flex",
    alignItems: "center",
  };
  const iconStyle = { marginRight: 8 };
  switch (notification.entityType) {
    case "jobDoc":
      return (
        <MenuItem
          style={{ fontSize: 14 }}
          component={Link}
          to={`/travaux/${notification.entityId}`}
        >
          <div style={menuStyle}>
            <DescriptionIcon            
              color="secondary"
              style={iconStyle}
            />
            <div>
              {notification.entityName} <b>{notification.message}</b>
            </div>
          </div>
        </MenuItem>
      );
    case "jobInfo":
      return (
        <MenuItem
          style={{ fontSize: 14 }}
          component={Link}
          to={`/travaux/${notification.entityId}`}
        >
          <div style={menuStyle}>
            <WarningIcon color="secondary" style={iconStyle} />
            <div>
              {notification.entityName} <b>{notification.message}</b>
            </div>
          </div>
        </MenuItem>
      );
    case "jobCash":
      return (
        <MenuItem
          style={{ fontSize: 14 }}
          component={Link}
          to={`/travaux/${notification.entityId}`}
        >
          <div style={menuStyle}>
            <EuroIcon color="secondary" style={iconStyle} />
            <div>
              {notification.entityName} <b>{notification.message}</b>
            </div>
          </div>
        </MenuItem>
      );
    case "empInfo":
      return (
        <MenuItem
          style={{ fontSize: 14 }}
          component={Link}
          to={`/personnel/${notification.entityId}`}
        >
          <div style={menuStyle}>
            <PeopleIcon color="secondary" style={iconStyle} />
            <div>
              {notification.entityName} <b>{notification.message}</b>
            </div>
          </div>
        </MenuItem>
      );
    default:
      return null;
  }
};

export default Notification;
