import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import MaterialTable, { MTableEditField } from 'material-table';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import { IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

import Dashboard from '../components/Dashboard';
import { expenseService } from '../services/expenseService';
import { getDelimiter, Localization } from '../helpers/localization';
import { FileUploadCell } from '../components/FileUploadCell';
import { getCurrentUser } from '../helpers/authentication';
import { getTableBodyHeights } from '../helpers/table';
import useDebounce from '../hooks/useDebounce';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';

const options = {
  draggable: false,
  actionsColumnIndex: -1,
  pageSizeOptions: [10, 25, 50],
  paging: true,
  search: false,
  pageSize: 10,
  ...getTableBodyHeights(),
  exportButton: true,
  exportFileName: 'travaux',
  exportDelimiter: getDelimiter(),
};

const AccountingBuy = () => {
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    startDatePaid: moment().startOf('month').format('YYYY-MM-01'),
    endDatePaid: moment()
      .startOf('month')
      .add(1, 'months')
      .format('YYYY-MM-01'),
    contractor: '',
  });
  const debouncedContractor = useDebounce(filters.contractor, 500);

  const clearFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      contractor: '',
      startDatePaid: '',
      endDatePaid: '',
    });
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name) => (date) => {
    if (!moment(date).isValid()) {
      setFilters((prev) => ({ ...prev, [name]: '' }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: moment(date).format('YYYY-MM-DD'),
      }));
    }
  };

  const { data, isLoading } = useQuery(
    ['expenses', { ...filters, contractor: debouncedContractor }],
    () => expenseService.getAll({ ...filters, contractor: debouncedContractor })
  );

  const queryClient = useQueryClient();
  const { mutate: create } = useMutation({
    mutationFn: (data) => expenseService.create(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['expenses'] }),
  });
  const { mutate: update } = useMutation({
    mutationFn: (data) => expenseService.update(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['expenses'] }),
  });
  const { mutate: deleteExpense } = useMutation({
    mutationFn: (id) => expenseService.deleteExpense(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['expenses'] }),
  });

  const editable = {
    onRowAdd: async (newData) => {
      const datePaid = moment(newData.datePaid).isValid()
        ? moment(newData.datePaid).format('YYYY-MM-DD')
        : null;
      const item = {
        id: newData.id,
        name: newData.name,
        date: moment(newData.date).format('YYYY-MM-DD'),
        contractor: newData.contractor,
        value: parseFloat(newData.value),
        paid: newData.paid,
        datePaid: datePaid,
      };
      await create(item);
    },
    onRowUpdate: async (newData) => {
      const datePaid = moment(newData.datePaid).isValid()
        ? moment(newData.datePaid).format('YYYY-MM-DD')
        : null;
      const item = {
        id: newData.id,
        name: newData.name,
        contractor: newData.contractor,
        date: moment(newData.date).format('YYYY-MM-DD'),
        value: parseFloat(newData.value),
        paid: newData.paid,
        datePaid: datePaid,
      };
      await update(item);
    },
    onRowDelete: async (oldData) => {
      await deleteExpense(oldData.id);
    },
  };

  const columns = [
    { title: 'Nom', field: 'name' },
    {
      title: 'Date',
      field: 'date',
      type: 'date',
      render: (rowData) => (
        <div>{moment(rowData.date).format('DD.MM.YYYY')}</div>
      ),
      initialEditValue: moment(),
    },
    {
      title: `Montant`,
      field: 'value',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
      headerStyle: { textAlign: 'right' },
      editComponent: (props) => <MTableEditField {...props} type='number' />,
    },
    { title: 'Fournisseur', field: 'contractor' },
    { title: 'Payé', field: 'paid', type: 'boolean' },
    {
      title: 'Date a payé',
      field: 'datePaid',
      type: 'date',
      render: (rowData) => (
        <div>
          {moment(rowData.datePaid).isValid()
            ? moment(rowData.datePaid).format('DD.MM.YYYY')
            : ''}
        </div>
      ),
      initialEditValue: moment(),
    },
    {
      title: 'Document',
      field: 'filePath',
      editable: 'never',
      export: false,
      sorting: false,
      render: (rowData) =>
        rowData &&
        FileUploadCell(
          rowData,
          rowData.filePath,
          getCurrentUser().token,
          'api/Expenses/Files',
          handleFileSubmit,
          handleFileDeleteStart
        ),
    },
  ];

  const handleFileSubmit = (expenseId) => async (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('file', event.target.files[0]);
    await uploadExpenseFile({ expenseId, formData });
  };

  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmClose = () => setConfirmOpen(false);

  const handleFileDeleteStart = (id) => () => {
    setConfirmOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(0);
  const handleFileDeleteCommit = async () => {
    setConfirmOpen(false);
    await deleteExpenseFile(deleteId);
  };

  const { mutate: uploadExpenseFile, isLoading: isUploading } = useMutation({
    mutationFn: ({ expenseId, formData }) =>
      expenseService.uploadFile(expenseId, formData),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['expenses'] }),
  });

  const { mutate: deleteExpenseFile, isLoading: isDeleting } = useMutation({
    mutationFn: () => expenseService.deleteFile(deleteId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['expenses'] }),
  });

  const classes = useStyles();

  const loading = isLoading || isDeleting || isUploading;

  return (
    <Dashboard title='Contable - Achat'>
      <MaterialTable
        data={data}
        editable={editable}
        title={
          <div className={classes.title}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
              <DatePicker
                clearable
                clearLabel='Supprimer'
                cancelLabel='Annuler'
                label='Dater de'
                labelFunc={(date) =>
                  date ? moment(date).format('DD.MM.YYYY.') : ''
                }
                className={clsx(classes.datePicker, classes.input)}
                value={filters.startDate.length ? filters.startDate : null}
                onChange={handleDateChange('startDate')}
              />
              <DatePicker
                clearable
                clearLabel='Supprimer'
                cancelLabel='Annuler'
                label='Date de'
                labelFunc={(date) =>
                  date ? moment(date).format('DD.MM.YYYY.') : ''
                }
                className={clsx(classes.datePicker, classes.input)}
                value={filters.endDate.length ? filters.endDate : null}
                onChange={handleDateChange('endDate')}
              />
              <TextField
                label='Fournisseur'
                value={filters.contractor}
                className={classes.input}
                onChange={handleChange('contractor')}
                margin='none'
              />
              <DatePicker
                clearable
                clearLabel='Supprimer'
                cancelLabel='Annuler'
                label='Dater payé de'
                labelFunc={(date) =>
                  date ? moment(date).format('DD.MM.YYYY.') : ''
                }
                className={clsx(classes.datePicker, classes.input)}
                value={
                  filters.startDatePaid.length ? filters.startDatePaid : null
                }
                onChange={handleDateChange('startDatePaid')}
              />
              <DatePicker
                clearable
                clearLabel='Supprimer'
                cancelLabel='Annuler'
                label='Date payé de'
                labelFunc={(date) =>
                  date ? moment(date).format('DD.MM.YYYY.') : ''
                }
                className={clsx(classes.datePicker, classes.input)}
                value={filters.endDatePaid.length ? filters.endDatePaid : null}
                onChange={handleDateChange('endDatePaid')}
              />
            </MuiPickersUtilsProvider>
            <Tooltip title='Effacer les filtres'>
              <IconButton edge='start' color='inherit' onClick={clearFilters}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
        columns={columns}
        options={options}
        isLoading={loading}
        localization={Localization}
      />
      <ConfirmDeleteDialog
        open={confirmOpen}
        handleClose={handleConfirmClose}
        handleDelete={handleFileDeleteCommit}
        text={'Confirmer la suppression de fichier?'}
      />
    </Dashboard>
  );
};

export default AccountingBuy;

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 100,
  },
  input: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
  },
}));
