export const ScheduleType = {
  Regular: 'Regular',
  NoWork: 'NoWork',
  Sick: 'Sick',
  JustifiedLeave: 'JustifiedLeave',
  UnjustifiedLeave: 'UnjustifiedLeave',
};

export const ScheduleDialogType = {
  Regular: 'Regular',
  Leave: 'Leave',
};

export const ScheduleTypeFR = {
  Regular: 'Regular',
  NoWork: 'Pas de travail',
  Sick: 'Malade',
  JustifiedLeave: 'Congé justifié',
  UnjustifiedLeave: 'Congé injustifié',
};

export const ScheduleTypeList = [
  ScheduleType.NoWork,
  ScheduleType.Sick,
  ScheduleType.JustifiedLeave,
  ScheduleType.UnjustifiedLeave,
];
