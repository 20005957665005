import React from 'react'
import { Localization, getDelimiter } from '../../helpers/localization';
import moment from 'moment';
import MaterialTable from 'material-table';

const Buildings = (props) => {

    const { name, data, date, loading, setBuildingName, getFloors } = props

    const dateString = moment(date).isValid() ? moment(date).format('MMMM YYYY') : 'Total'
    const title = `${name} - ${dateString}`

    const handleClick = (event, rowData) => {
        setBuildingName(`Étages - ${rowData.name}`)
        getFloors(rowData.id)
    }

    const options = {
        search: false,
        paging: false,
        maxBodyHeight: 360,
        minBodyHeight: 360,
        draggable: false,
        actionsColumnIndex: -1,
        exportButton: true,
        exportFileName: `analyse-${name}-${dateString}`,
        exportDelimiter: getDelimiter(),
        exportPdf: () => { }
    }


    const columns = [
        { title: 'Batiment', field: 'name', cellStyle: { minWidth: '200px' } },
        { title: '80%', field: 'number80' },
        { title: '90%', field: 'number90' },
        { title: '100%', field: 'number100' },
        { title: 'TS', field: 'numberTS' },
        { title: `Posé ${dateString}`, field: 'totalPosedMonth' },
        { title: 'Restant Total', field: 'remaining' },
        { title: 'Total %', field: 'percentage', render: rowData => <div>{`${Number(rowData.percentage).toFixed(2)}%`}</div> }
    ]

    return (
        <MaterialTable
            options={options}
            title={title}
            columns={columns}
            data={data}
            localization={Localization}
            isLoading={loading}
            onRowClick={handleClick}
        />
    )
}

export default Buildings;