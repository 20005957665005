import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale'
import moment from 'moment';
import { Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

function VehicleAddDialog(props) {
    const classes = useStyles();

    const { open, handleClose, handleAdd, vehicleToAdd, setVehicleToAdd } = props;

    const handleChange = name => event => {
        var value;
        if (name === 'registrationDate') {
            value = moment(event).isValid()
                ? moment(event).format('YYYY-MM-DD')
                : null
        } else {
            value = event.target.value;
        }
        setVehicleToAdd({ ...vehicleToAdd, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAdd();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Ajouter de véhicule
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent className={classes.container} >
                    <TextField
                        autoFocus
                        label='Nom'
                        required
                        defaultValue={vehicleToAdd.name}
                        className={classes.textField}
                        onBlur={handleChange('name')}
                        margin="normal"
                    />
                    <TextField
                        label='Immatriculation'
                        efaultValue={vehicleToAdd.registration}
                        className={classes.textField}
                        onBlur={handleChange('registration')}
                        margin="normal"
                    />                   
                    <TextField
                        label='Chauffeur'                        
                        defaultValue={vehicleToAdd.driver}
                        className={classes.textField}
                        onBlur={handleChange('driver')}
                        margin="normal"
                    />                                     
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>                        
                        <KeyboardDatePicker
                            margin="normal"
                            label="Controle technique"
                            cancelLabel="Annuler"
                            format="dd.MM.yyyy"
                            value={vehicleToAdd.registrationDate}
                            className={classes.textField}
                            onChange={handleChange('registrationDate')}
                            KeyboardButtonProps={{
                                'aria-label': 'Changer la date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default VehicleAddDialog;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        textAlign: 'left',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actions: {
        width: '100%'
    }
}));