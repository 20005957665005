import React, { useState } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { IconButton, Menu } from '@material-ui/core';
import { useNotifications } from '../hooks/useNotifications';

import Notification from './Notification';

const Notifications = () => {
  const { data: notifications } = useNotifications();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton style={{ marginRight: 16 }} onClick={handleClick}>
        <Badge badgeContent={notifications?.length} color='secondary'>
          <NotificationsIcon style={{ color: 'white' }} />
        </Badge>
      </IconButton>
      {notifications?.length > 0 && (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {notifications?.map((notification, i) => (
            <div key={i}>
              <Notification notification={notification} />
            </div>
          ))}
        </Menu>
      )}
    </>
  );
};

export default Notifications;
