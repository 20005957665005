export const ReserveStatus = {
    InProgress: '1-InProgress',
    AwaitingMaterial: '2-AwaitingMaterial',
    Completed: '3-Completed',
}

export const ReserveStatusFR = {
    '1-InProgress': 'En cours',
    '2-AwaitingMaterial': 'Fini',
    '3-Completed': 'Attente matériaux',
}

export const ReserveStatusList = [ReserveStatus.InProgress, ReserveStatus.AwaitingMaterial, ReserveStatus.Completed]