import React from 'react';

const SVGLegend = () => {
  return (
    <>
      <rect x='0' y='0' width='125' height='155' fill='white' stroke='black'></rect>
      <rect x='5' y='5' width='20' height='20' fill='#c62828' stroke='black'></rect>
      <text x='30' y='25' fontFamily='Verdana' fontSize='25'>
        erreur
      </text>
      <rect x='5' y='30' width='20' height='20' fill='#9e9e9e' stroke='black'></rect>
      <text x='30' y='50' fontFamily='Verdana' fontSize='25'>
        0%
      </text>
      <rect x='5' y='55' width='20' height='20' fill='#ffeb3b' stroke='black'></rect>
      <text x='30' y='75' fontFamily='Verdana' fontSize='25'>
        80%
      </text>
      <rect x='5' y='80' width='20' height='20' fill='#03a9f4' stroke='black'></rect>
      <text x='30' y='100' fontFamily='Verdana' fontSize='25'>
        90%
      </text>
      <rect x='5' y='105' width='20' height='20' fill='#8bc34a' stroke='black'></rect>
      <text x='30' y='125' fontFamily='Verdana' fontSize='25'>
        100%
      </text>
      <rect x='5' y='130' width='20' height='20' fill='#FFC107' stroke='black'></rect>
      <text x='30' y='150' fontFamily='Verdana' fontSize='25'>
        TS
      </text>
      '
    </>
  );
};

export default SVGLegend;
