import React from 'react';
import { List } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PeopleIcon from '@material-ui/icons/People';
import TodayIcon from '@material-ui/icons/Today';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BusinessIcon from '@material-ui/icons/Business';
import LinkListItem from './LinkListItem';
import BarChartIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';
import EuroIcon from '@material-ui/icons/Euro';

import { Role } from '../helpers/role';

function LinkList({ user }) {
  return (
    <List>
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem
          text='Analyse chantier'
          icon={<BarChartIcon />}
          to='/analyse/chantier/'
        />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem
          text='Analyse planning'
          icon={<DashboardIcon />}
          to='/analyse/planning/'
        />
      )}
      {[Role.Admin, Role.Manager, Role.User].includes(user.role) && (
        <LinkListItem
          text='Analyse pose'
          icon={<BusinessIcon />}
          to='/analyse/pose/'
        />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem text='Planning' icon={<TodayIcon />} to='/planning/' />
      )}
      {[Role.Admin, Role.Manager, Role.Reserves].includes(user.role) && (
        <LinkListItem text='Reserves' icon={<BuildIcon />} to='/reserves/' />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem text='Travaux' icon={<ApartmentIcon />} to='/travaux/' />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem text='Personnel' icon={<PeopleIcon />} to='/personnel/' />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem
          text='Contable - Achat'
          icon={<EuroIcon />}
          to='/contable/achat/'
        />
      )}
      {[Role.Admin, Role.Manager].includes(user.role) && (
        <LinkListItem
          text='Véhicules'
          icon={<DirectionsCarIcon />}
          to='/vehicules/'
        />
      )}
      {[Role.Admin].includes(user.role) && (
        <LinkListItem
          text='Utilisateurs'
          icon={<AccountBoxIcon />}
          to='/utilisateurs/'
        />
      )}
    </List>
  );
}

export default LinkList;
