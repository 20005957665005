import React, { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import MaterialTable, { MTableCell } from 'material-table';
import { employeeService } from '../services/employeeService';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import EmployeeAddDialog from '../dialogs/EmployeeAddDialog';
import { EmployeeStatus, EmployeeStatusFR, EmployeeStatusList } from '../helpers/employeeStatus';
import { ContractType, ContractTypeFR } from '../helpers/contractType';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Localization } from '../helpers/localization';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { getTableBodyHeights } from '../helpers/table';

const Employees = () => {
    const history = useHistory()
    const classes = useStyles()
    const columns = [
        { title: 'Prénom', field: 'firstName', cellStyle: { maxWidth: '150px', overflowWrap: 'break-word', paddingRight: 0 } },
        { title: 'Nom', field: 'lastName', cellStyle: { maxWidth: '150px', overflowWrap: 'break-word', paddingLeft: 0 } },
        {
            title: 'Statut',
            field: 'status',
            render: rowData => <div>{EmployeeStatusFR[rowData.status]}</div>,
            customFilterAndSearch: (term, rowData) => EmployeeStatusFR[rowData.status].toUpperCase().includes(term.toUpperCase()),
        },
        {
            title: 'Journée',
            field: 'salary',
            type: 'currency',
            currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
        },
        {
            title: 'Contrat',
            field: 'contractType',
            render: rowData => <div>{ContractTypeFR[rowData.contractType]}</div>,
            customFilterAndSearch: (term, rowData) => ContractTypeFR[rowData.contractType].toUpperCase().includes(term.toUpperCase())
        },
        { title: 'Commencé', field: 'workStart', render: rowData => { return rowData.workStart && <div>{moment(rowData.workStart).format('DD.MM.YYYY')}</div> } },
        { title: 'Fini', field: 'workEnd', render: rowData => { return rowData.workEnd && <div>{moment(rowData.workEnd).format('DD.MM.YYYY')}</div> } },
    ];

    const options = {
        draggable: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 25, 50],
        paging: true,
        pageSize: 10,
        ...getTableBodyHeights()
    }

    const actions = [
        {
            icon: 'add_box',
            tooltip: 'Ajouter',
            isFreeAction: true,
            onClick: (event) => {
                setOpenAdd(true);
            }
        },
        rowData => ({
            icon: 'more_horiz',
            tooltip: 'Détails',
            onClick: (event, rowData) => {
                history.push(String(rowData.id))
            }
        }),
        rowData => ({
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
                setDeleteId(rowData.id);
                setConfirmOpen(true);
            }
        }),
    ]

    const [employeeToAdd, setEmployeeToAdd] = useState({
        firstName: '',
        lastName: '',
        salary: '',
        accountNumber: '',
        contractType: ContractType.Indefinite,
        workStart: null,
        workEnd: null,
        status: EmployeeStatus.Active,
        note: '',
    })
    const handleAddEmployee = () => {
        employeeService.create(employeeToAdd)
            .then(() => {
                handleCloseAdd();
                getEmployees();
            })
    }

    const [openAdd, setOpenAdd] = useState(false)
    const handleCloseAdd = () => {
        setOpenAdd(false);
        setEmployeeToAdd({
            firstName: '',
            lastName: '',
            salary: '',
            accountNumber: '',
            contractType: ContractType.Indefinite,
            workStart: null,
            workEnd: null,
            status: EmployeeStatus.Active,
            note: '',
        });
    }

    const [deleteId, setDeleteId] = useState(0)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }
    const handleDeleteEmployee = () => {
        employeeService.deleteEmployee(deleteId)
            .then(() => {
                handleConfirmClose()
                getEmployees()
            })
    }

    const [data, setData] = useState([])
    const getEmployees = (params) => {
        setLoading(true)
        employeeService.getAll(params)
            .then(res => {
                setData(res)
                setLoading(false)
            })
    }

    const [filter, setFilter] = useState({
        status: ''
    })

    const handleStatusChange = (e) => {
        const value = e.target.value
        setFilter({ ...filter, status: value })
    }
    useEffect(() => {
        getEmployees([
            {
                name: 'status',
                value: filter.status
            }
        ])
    }, [filter.status])

    const [loading, setLoading] = useState(false)

    return (
        <Dashboard title={'Personnel'}>
            <MaterialTable
                columns={columns}
                data={data}
                options={options}
                actions={actions}
                title={
                    <FormControl margin="normal" className={classes.status}>
                        <InputLabel>Statut</InputLabel>
                        <Select
                            value={filter.status}
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={''}>Tout</MenuItem>
                            {EmployeeStatusList.map((status, i) =>
                                <MenuItem key={i} value={status}>{EmployeeStatusFR[status]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
                isLoading={loading}
                localization={Localization}
                components={{
                    Cell: props => <MTableCell {...props} style={{ padding: '0px 0px 0px 16px' }} />
                }}
            />
            <EmployeeAddDialog
                open={openAdd}
                handleClose={handleCloseAdd}
                handleAdd={handleAddEmployee}
                employeeToAdd={employeeToAdd}
                setEmployeeToAdd={setEmployeeToAdd}
            />
            <ConfirmDeleteDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleDelete={handleDeleteEmployee}
                text={'Confirmer la suppression de personnel?'}
            />
        </Dashboard>
    )
}

export default Employees

const useStyles = makeStyles(theme => ({
    status: {
        marginTop: 0,
        minWidth: 140,
        width: 140,
        marginLeft: theme.spacing(1)
    }
}));