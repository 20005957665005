import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Buildings from './Buildings';
import Floors from './Floors';
import Windows from './Windows';
import MultipleWindowsAddDialog from '../../dialogs/MultipleWindowsAddDialog';
import { buildingService } from '../../services/buildingService';
import { floorService } from '../../services/floorService';
import { windowService } from '../../services/windowService';
import { employeeService } from '../../services/employeeService';
import { EmployeeStatus } from '../../helpers/employeeStatus';
import { useParams } from 'react-router-dom';

const MonitoringAdmin = (props) => {
    const { jobId, setSnackbar, windows, getWindows, windowsLoading } = props

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false);
    }

    const [building, setBuilding] = useState({ name: '' })
    const getBuilding = (id) => {
        if (id === 0) {
            setBuilding({ name: '' })
            return
        }
        buildingService.get(id)
            .then(res => setBuilding({ ...res }))
            .catch(() => { })
    }

    const [floor, setFloor] = useState({
        id: 0,
        name: '',
        imageWidth: 0,
        imageheight: 0
    })
    const getFloor = (id) => {
        if (id === 0) {
            setFloor({
                id: 0,
                name: '',
                imageWidth: 0,
                imageheight: 0
            })
            return
        }
        floorService.get(id)
            .then(res => setFloor({ ...res }))
            .catch(() => { })
    }

    const [employees, setEmployees] = useState({})
    const getEmployees = () => {
        employeeService.getAll([{
            name: 'status',
            value: EmployeeStatus.Active
        }])
            .then(res => {
                var lookup = {
                    0: 'Pas de fournisseur'
                }
                res.forEach(employee => {
                    lookup = {
                        ...lookup,
                        [employee.id]: `${employee.firstName} ${employee.lastName}`
                    }
                });
                setEmployees(lookup)
            })
            .catch(() => setSnackbar({
                open: true,
                variant: 'error',
                message: 'Erreur'
            }))
    }

    const [buildingsLoading, setBuildingsLoading] = useState(false)
    const [buildings, setBuildings] = useState([])
    const getBuildings = (id) => {
        setBuildingsLoading(true)
        buildingService.getAll(id)
            .then(res => {
                setBuildings(res)
                setBuildingsLoading(false)
            })
            .catch(() => setSnackbar({
                open: true,
                variant: 'error',
                message: 'Erreur'
            }))
    }

    const [floorsLoading, setFloorsLoading] = useState(false)
    const [floors, setFloors] = useState([])
    const getFloors = (id) => {
        setFloorsLoading(true)
        floorService.getAll(id)
            .then(res => {
                setFloors(res)
                setFloorsLoading(false)
            })
    }

    const handleAddMultiple = (data) => {
        const dataToSend = {
            floorId: +floorId,
            ...data
        }
        windowService.createMultiple(dataToSend)
            .then(() => getWindows(floorId))
            .catch(() => setSnackbar({
                open: true,
                variant: 'error',
                message: 'Erreur'
            }))
    }

    const { buildingId, floorId } = useParams()

    const updatePercentages = () => {
        getBuildings(jobId)
        getFloors(buildingId)
    }

    useEffect(() => {
        getBuildings(jobId)
    }, [jobId])

    useEffect(() => {
        const id = buildingId ? buildingId : 0
        getFloors(id)
        getBuilding(id)
    }, [buildingId])

    useEffect(() => {
        const id = floorId ? floorId : 0
        getWindows(id)
        getFloor(id)
    }, [floorId])

    useEffect(() => {
        getEmployees()
    }, [])

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Buildings
                        data={buildings}
                        getBuildings={getBuildings}
                        jobId={jobId}
                        buildingId={+buildingId}
                        loading={buildingsLoading}
                        setSnackbar={setSnackbar}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Floors
                        jobId={jobId}
                        data={floors}
                        title={building.name}
                        getFloors={getFloors}
                        buildingId={+buildingId}
                        loading={floorsLoading}
                        floorId={+floorId}
                        updatePercentages={updatePercentages}
                        setSnackbar={setSnackbar}
                        setLoading={setFloorsLoading}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 8 }} />
            <Windows
                data={windows}
                title={`${building.name} ${floor.name}`}
                employees={employees}
                loading={windowsLoading}
                getWindows={getWindows}
                floorId={+floorId}
                setOpen={setOpen}
                updatePercentages={updatePercentages}
                setSnackbar={setSnackbar}
            />
            <MultipleWindowsAddDialog
                open={open}
                handleClose={handleClose}
                handleAddMultiple={handleAddMultiple}
            />
        </div>
    )
}

export default MonitoringAdmin;