import React from 'react';
import MaterialTable, { MTableEditField } from 'material-table';
import { Paper } from '@material-ui/core';
import { Localization } from '../helpers/localization';
import { getCurrentUser } from '../helpers/authentication';
import moment from 'moment';
import { expenseService } from '../services/expenseService';
import { FileUploadCell } from './FileUploadCell';

const Expenses = React.memo(
  (props) => {
    const {
      job,
      setMessage,
      setVariant,
      loading,
      setOpenSnackbar,
      getJob,
      to,
      uploadFile,
      deleteFile,
    } = props;

    const possibleItems = preProcessData(job.jobItems);

    const columns = [
      { title: 'Nom', field: 'name' },
      {
        title: 'Date',
        field: 'date',
        type: 'date',
        render: (rowData) => (
          <div>{moment(rowData.date).format('DD.MM.YYYY')}</div>
        ),
        initialEditValue: moment(),
      },
      {
        title: 'Dépense type',
        field: 'jobItemId',
        lookup: possibleItems,
      },
      {
        title: `Montant`,
        field: 'value',
        type: 'currency',
        currencySetting: { currencyCode: 'EUR', locale: 'fr-FR' },
        headerStyle: { textAlign: 'right' },
        editComponent: (props) => <MTableEditField {...props} type='number' />,
      },
      { title: 'Payé', field: 'paid', type: 'boolean' },
      {
        title: 'Date a payé',
        field: 'datePaid',
        type: 'date',
        render: (rowData) => (
          <div>
            {moment(rowData.datePaid).isValid()
              ? moment(rowData.datePaid).format('DD.MM.YYYY')
              : ''}
          </div>
        ),
        initialEditValue: moment(),
      },
      {
        title: 'Document',
        field: 'filePath',
        editable: 'never',
        sorting: false,
        render: (rowData) =>
          rowData &&
          FileUploadCell(
            rowData,
            rowData.filePath,
            getCurrentUser().token,
            to,
            handleFileSubmit,
            handleFileDelete
          ),
      },
    ];

    const handleFileSubmit = (expenseId) => (event) => {
      event.preventDefault();
      var formData = new FormData();
      formData.append('file', event.target.files[0]);
      uploadFile(expenseId, formData);
    };

    const handleFileDelete = (id) => () => {
      deleteFile(id);
    };

    const options = {
      draggable: false,
      actionsColumnIndex: -1,
      pageSizeOptions: [],
      paging: true,
      pageSize: 5,
      search: false,
      addRowPosition: 'first',
    };

    const editable = {
      onRowAdd: (newData) =>
        new Promise((resolve, reject) => {
          const datePaid = moment(newData.datePaid).isValid()
            ? moment(newData.datePaid).format('YYYY-MM-DD')
            : null;
          const item = {
            id: newData.id,
            jobId: job.id,
            name: newData.name,
            date: moment(newData.date).format('YYYY-MM-DD'),
            value: parseFloat(newData.value),
            paid: newData.paid,
            datePaid: datePaid,
            jobItemId: parseInt(newData.jobItemId),
          };
          expenseService
            .create(item)
            .then(() => {
              getJob(job.id);
              resolve();
            })
            .catch(() => {
              setVariant('error');
              setMessage('Entrée invalide');
              setOpenSnackbar(true);
              reject();
            });
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const datePaid = moment(newData.datePaid).isValid()
            ? moment(newData.datePaid).format('YYYY-MM-DD')
            : null;
          const item = {
            id: newData.id,
            jobId: job.id,
            name: newData.name,
            date: moment(newData.date).format('YYYY-MM-DD'),
            value: parseFloat(newData.value),
            paid: newData.paid,
            datePaid: datePaid,
            jobItemId: parseInt(newData.jobItemId),
          };
          expenseService
            .update(item)
            .then(() => {
              getJob(job.id);
              resolve();
            })
            .catch(() => {
              setVariant('error');
              setMessage('Entrée invalide');
              setOpenSnackbar(true);
              reject();
            });
        }),
      onRowDelete: (oldData) =>
        new Promise((resolve, reject) => {
          expenseService
            .deleteExpense(oldData.id)
            .then(() => {
              getJob(job.id);
              resolve();
            })
            .catch(() => {
              setVariant('error');
              setMessage('Erreur');
              setOpenSnackbar(true);
              reject();
            });
        }),
    };

    return (
      <div>
        <MaterialTable
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          data={job.expenses}
          columns={columns}
          options={options}
          title={'Dépenses - Troškovi'}
          editable={editable}
          isLoading={loading}
          localization={Localization}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.job.expenses) ===
      JSON.stringify(nextProps.job.expenses) &&
    prevProps.loading === nextProps.loading
);

export default Expenses;

function preProcessData(data) {
  var newData = {};
  data.forEach((e) => {
    newData = { ...newData, [e.id]: e.name };
  });
  return newData;
}
