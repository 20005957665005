import { authHeader, handleResponse } from '../helpers/authentication'

export const buildingService = {
    get,
    getAll,
    create,
    update,
    deleteBuilding,
    getForAnalysis
}

function get(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings/${id}`, requestOptions).then(handleResponse)     
}

function getAll(jobId) {   
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings/byJob/${jobId}`, requestOptions).then(handleResponse)     
}

function create(building) {
    const requestOptions =
    {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(building)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings`, requestOptions).then(handleResponse)        
}

function update(building) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(building)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings/${building.id}`, requestOptions).then(handleResponse)        
}

function deleteBuilding(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: {
            ...authHeader(),            
        },
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings/${id}`, requestOptions).then(handleResponse)        
}

function getForAnalysis(params, jobId) {
    var paramArray = params === undefined ? [] : params
    var paramString = '?'
    paramArray.forEach(p => paramString = (p.value !== null && p.value !== undefined) ? paramString.concat(`${p.name}=${p.value}&`) : paramString);
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Buildings/Analysis/${jobId}${paramString}`, requestOptions).then(handleResponse)
}