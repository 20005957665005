import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { Localization, getDelimiter } from '../../helpers/localization';

const Jobs = (props) => {
    const { data, handleDateChange, date, loading, setJobName, getBuildings, setEmployeeName, getEmployees } = props

    const classes = useStyles()
    const history = useHistory()

    const dateString = moment(date).isValid() ? moment(date).format('MMMM YYYY') : 'Total'

    const handleClick = (event, rowData) => {
        setJobName(`Bâtiments - ${rowData.name}`)
        setEmployeeName(`Fournisseurs - ${rowData.name}`)
        getBuildings(rowData.id)
        getEmployees(rowData.id)
    }

    const options = {
        search: false,
        paging: false,
        maxBodyHeight: 360,
        minBodyHeight: 360,
        draggable: false,
        actionsColumnIndex: -1,
        exportButton: true,
        exportFileName: `analyse-chatiers-${dateString}`,
        exportDelimiter: getDelimiter(),
        exportPdf: () => {},
    }

    const actions = [
        {
            icon: 'business',
            tooltip: "Surveiilance",
            onClick: (event, rowData) => {
                event.stopPropagation()
                history.push(`/surveillance/${rowData.id}/`)
            }
        }
    ]


    const columns = [
        { title: 'Chantier', field: 'name', cellStyle: { minWidth: '200px'}  },
        { title: '80%', field: 'number80' },
        { title: '90%', field: 'number90' },
        { title: '100%', field: 'number100' },
        { title: 'TS', field: 'numberTS' },
        { title: `Posé ${dateString}`, field: 'totalPosedMonth' },
        { title: 'Restant Total', field: 'remaining' },
        { title: 'Total %', field: 'percentage', render: rowData => <div>{`${Number(rowData.percentage).toFixed(2)}%`}</div> }
    ]

    const title = <div className={classes.toolbarTop}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
            <DatePicker
                cancelLabel="Annuler"
                views={["month", "year"]}
                label="Entrer le mois:"
                className={classes.datePicker}
                clearable
                clearLabel='supprimer'
                value={date}
                onChange={(date) => handleDateChange(date)}
            />
        </MuiPickersUtilsProvider>
        <Typography variant="h6">
            {`Chantiers - ${dateString}`}
        </Typography>
    </div>

    return (
        <MaterialTable
            options={options}
            title={title}
            columns={columns}
            data={data}
            actions={actions}
            localization={Localization}
            isLoading={loading}
            onRowClick={handleClick}
        />
    )
}

export default Jobs;

const useStyles = makeStyles(theme => ({
    panel: {
        padding: theme.spacing(1)
    },
    toolbarTop: {
        display: 'flex',
        alignItems: 'center',
    },
    datePicker: {
        width: '150px',
        marginRight: theme.spacing(1)
    },
}));