import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import { PrivateRoute } from '../../containers/PrivateRoute';
import { useParams, useHistory } from 'react-router-dom';
import MonitoringAdmin from './MonitoringAdmin';
import MonitoringMap from './MonitoringMap';
import { jobService } from '../../services/jobService';
import { Snackbar, makeStyles } from '@material-ui/core';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';
import { getCurrentUser } from '../../helpers/authentication';
import { windowService } from '../../services/windowService';
import { Role } from '../../helpers/role';
import MonitoringManager from './MonitoringManager';

const Monitoring = (props) => {
  const { match } = props;
  const { jobId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [windows, setWindows] = useState([]);
  const [windowsLoading, setWindowsLoading] = useState(false);
  const getWindows = (id) => {
    getJob(jobId);
    setWindowsLoading(true);
    windowService
      .getAll(id)
      .then((res) => {
        res.forEach((window) => {
          if (!window.employeeId) {
            window.employeeId = 0;
          }
        });
        setWindows(res);
        setWindowsLoading(false);
      })
      .catch();
  };

  const [job, setJob] = useState({
    name: '',
    clientName: '',
  });
  const getJob = (id) => {
    jobService
      .get(id)
      .then((res) => setJob({ ...res }))
      .catch(() =>
        setSnackbar({
          open: true,
          variant: 'error',
          message: 'Erreur',
        })
      );
  };

  useEffect(() => {
    getJob(jobId);
  }, [jobId]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: 'success',
    message: '',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      variant: 'success',
      message: '',
    });
  };

  const title = (
    <div className={classes.hover} onClick={() => history.push(`/travaux/${jobId}`)}>
      {!isNaN(job.percentage) &&
        `${job.name} (${job.clientName}) - Total: ${Number(job.percentage).toFixed(2)}%`}
    </div>
  );

  const handleGlobal = (buildingId, floorId) => {
    if (getCurrentUser().role === Role.Admin) {
      if (!buildingId || !floorId) {
        history.push(`/surveillance/${jobId}/admin/`);
      } else {
        history.push(`/surveillance/${jobId}/admin/${buildingId}/${floorId}`);
      }
    } else {
      history.push(`/surveillance/${jobId}/`);
    }
  };

  return (
    <Dashboard title={title}>
      <PrivateRoute
        exact
        path={'/surveillance/:jobId/'}
        component={MonitoringManager}
        jobId={jobId}
        roles={[Role.Admin, Role.Manager, Role.User]}
      />
      <PrivateRoute
        path={'/surveillance/:jobId/admin/:buildingId?/:floorId?'}
        component={MonitoringAdmin}
        roles={[Role.Admin]}
        jobId={jobId}
        setSnackbar={setSnackbar}
        windows={windows}
        getWindows={getWindows}
        windowsLoading={windowsLoading}
      />
      <PrivateRoute
        path={`${match.url}/map/:floorId`}
        component={MonitoringMap}
        editing={getCurrentUser().role === Role.Admin}
        setSnackbar={setSnackbar}
        data={windows}
        jobId={jobId}
        getJob={getJob}
        getWindows={getWindows}
        handleGlobal={handleGlobal}
        roles={[Role.Admin, Role.Manager, Role.User]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}>
        <CustomSnackbarContent
          onClose={handleCloseSnackbar}
          variant={snackbar.variant}
          className={classes.snackbar}
          message={snackbar.message}
        />
      </Snackbar>
    </Dashboard>
  );
};

export default Monitoring;

const useStyles = makeStyles((theme) => ({
  snackbar: {
    margin: theme.spacing(1),
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
