import React, { useState } from 'react'
import { getCurrentUser } from '../helpers/authentication';
import { makeStyles, IconButton, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, TextField, InputAdornment } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const File = ({ item, to, handleFileDelete, handleFileUpdate }) => {
    const classes = useStyles();

    const [fileName, extension] = item.name.split('.');

    const [editing, setEditing] = useState(false)
    const [name, setName] = useState(fileName)

    const startEdit = () => {
        setEditing(true)
    }

    const stopEdit = () => {
        setEditing(false)
        setName(fileName)
    }

    const acceptEdit = (e) => {
        e.preventDefault()
        handleFileUpdate(item.id, { ...item, name: `${name}.${extension}` })
        stopEdit()
    }

    return (
        <ListItem>
            <ListItemIcon>
                <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    editing ?
                        <TextField
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{`.${extension}`}</InputAdornment>,
                            }}
                        />
                        :
                        <a
                            className={classes.listText}
                            href={`${process.env.REACT_APP_API_URL}${to}/${item.id}?token=${getCurrentUser().token}`}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            {item.name}
                        </a>
                }
            />
            <ListItemSecondaryAction>
                {
                    editing ?
                        <div>
                            <IconButton onClick={acceptEdit}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton onClick={stopEdit}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                        :
                        <div>
                            <IconButton onClick={startEdit}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={handleFileDelete(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                }

            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default File

const useStyles = makeStyles(theme => ({
    listText: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));