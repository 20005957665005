export const getColor = (item) => {
  let color;
  if (item.note.length > 0) {
    return '#c62828';
  }
  switch (item.percentage) {
    case 80:
      color = '#ffeb3b';
      break;
    case 90:
      color = '#03a9f4';
      break;
    case 100:
      color = '#8bc34a';
      break;
    case 500:
      color = '#FFC107';
      break;
    default:
      color = '#9e9e9e';
  }
  return color;
};
