import { useQuery } from 'react-query';

import notificationService from '../services/notificationService';

export const useNotifications = () => {
  const { data, isLoading } = useQuery('notifications', () =>
    notificationService.getAll()
  );

  return { data, isLoading };
};
