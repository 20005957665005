import React, { useState } from 'react';
import Dashboard from '../Dashboard';
import { makeStyles } from '@material-ui/core';
import Jobs from './Jobs';
import { jobService } from '../../services/jobService';
import { buildingService } from '../../services/buildingService';
import { floorService } from '../../services/floorService';
import moment from 'moment';
import Buildings from './Buildings';
import Floors from './Floors';
import Employees from './Employees';

const AnalysisMonitoring = () => {
    const classes = useStyles()

    const [date, setDate] = useState(new Date())
    const handleDateChange = (newDate) => {
        setDate(newDate)
        getJobs(newDate)
        resetAll()
    }

    const [jobs, setJobs] = useState([])
    const [jobName, setJobName] = useState('Bâtiments')
    const [jobsLoading, setJobsLoading] = useState(false)
    const getJobs = (newDate) => {
        const params = [{
            name: 'date',
            value: moment(newDate).format('YYYY-MM-01')
        }]
        setJobsLoading(true)
        jobService.getForAnalysis(params)
            .then(res => {
                setJobs(res)
                setJobsLoading(false)
            })
    }

    const [buildings, setBuildings] = useState([])
    const [buildingName, setBuildingName] = useState('Étages')
    const [buildingsLoading, setBuildingsLoading] = useState(false)
    const getBuildings = (jobId) => {
        const params = [{
            name: 'date',
            value: moment(date).format('YYYY-MM-01')
        }]
        setBuildingsLoading(true)
        buildingService.getForAnalysis(params, jobId)
            .then(res => {
                setBuildings(res)
                setBuildingsLoading(false)
            })
    }

    const [floors, setFloors] = useState([])
    const [floorsLoading, setFloorsLoading] = useState(false)
    const getFloors = (buildingId) => {
        const params = [{
            name: 'date',
            value: moment(date).format('YYYY-MM-01')
        }]
        setFloorsLoading(true)
        floorService.getForAnalysis(params, buildingId)
            .then(res => {
                setFloors(res)
                setFloorsLoading(false)
            })
    }

    const [employees, setEmployees] = useState([])
    const [employeeName, setEmployeeName] = useState('Fournisseurs')
    const [employeesLoading, setEmployeesLoading] = useState(false)
    const getEmployees = (jobId) => {
        const params = [{
            name: 'date',
            value: moment(date).format('YYYY-MM-01')
        }]
        setEmployeesLoading(true)
        jobService.getForAnalysisByEmployee(params, jobId)
            .then(res => {
                res.forEach(item => {
                    if (item.id === 0) {
                        item.name = 'Pas de fournisseur'
                    }
                })
                setEmployees(res)
                setEmployeesLoading(false)
            })
    }

    const resetAll = () => {
        setBuildings([])
        setFloors([])
        setEmployees([])
        setEmployeeName('Fournisseurs')
        setJobName('Bâtiments')
        setBuildingName('Étages')
    }

    useState(() => {
        getJobs()
    }, [])

    return (
        <Dashboard title='Analyse pose'>
            <div className={classes.panel}>
                <Jobs
                    date={date}
                    data={jobs}
                    loading={jobsLoading}
                    handleDateChange={handleDateChange}
                    setJobName={setJobName}
                    getBuildings={getBuildings}
                    getEmployees={getEmployees}
                    setEmployeeName={setEmployeeName}
                />
            </div>
            <div className={classes.panel}>
                <Employees
                    date={date}
                    data={employees}
                    loading={employeesLoading}
                    name={employeeName}
                />
            </div>
            <div className={classes.panel}>
                <Buildings
                    date={date}
                    name={jobName}
                    data={buildings}
                    setBuildingName={setBuildingName}
                    getFloors={getFloors}
                    loading={buildingsLoading}
                />
            </div>
            <div className={classes.panel}>
                <Floors
                    date={date}
                    name={buildingName}
                    data={floors}
                    loading={floorsLoading}
                />
            </div>
        </Dashboard>
    )
}

export default AnalysisMonitoring;

const useStyles = makeStyles(theme => ({
    panel: {
        paddingBottom: theme.spacing(1)
    }
}));

