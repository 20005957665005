import React, { useState } from 'react';
import { Dialog, makeStyles, DialogTitle, IconButton, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const MultipleWindowsAddDialog = (props) => {
    const classes = useStyles();

    const { open, handleClose, handleAddMultiple } = props

    const handleCloseDialog = () => {
        handleClose()
    }

    const [data, setData] = useState({
        start: '',
        number: 0
    })

    const handleChange = name => event => {
        var value;
        value = event.target.value;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const dataToSend = {
            start: data.start,
            number: +data.number
        }        
        setData({
            start: '',
            number: 0
        })
        handleAddMultiple(dataToSend);
        handleCloseDialog();
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>
                Ajouter plusieurs
                <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent className={classes.container}>
                    <TextField
                        label='Start'
                        required
                        defaultValue={data.start}
                        className={classes.textField}
                        onBlur={handleChange('start')}
                        margin="normal"
                    />
                    <TextField
                        label='Numero'
                        required
                        defaultValue={data.number}
                        className={classes.textField}
                        onBlur={handleChange('number')}
                        margin="normal"
                        type='number'
                        inputProps={{ min: 0, max: 40 }}
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default MultipleWindowsAddDialog;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        textAlign: 'left',
    },
    autocomplete: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: (200 + theme.spacing(1)) * 2,
        textAlign: 'left',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actions: {
        width: '100%'
    },
}));