import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ScheduleType, ScheduleTypeFR } from '../helpers/scheduleType';

const backgroundColorMap = (theme) => ({
  Regular: theme.palette.primary.main,
  NoWork: '#646b6b',
  Sick: '#ebb900',
  JustifiedLeave: '#1f6ec9',
  UnjustifiedLeave: '#DE4C45',
});

const hoverBackgroundColorMap = {
  Regular: '#689F38',
  NoWork: '#515757',
  Sick: '#eaa000',
  JustifiedLeave: '#1b5eb7',
  UnjustifiedLeave: '#ae362e',
};

const getName = (schedule) => {
  if (schedule.type === ScheduleType.Regular) {
    return schedule.jobName;
  } else {
    return ScheduleTypeFR[schedule.type];
  }
};

const useStyles = (schedule) =>
  makeStyles((theme) => {
    return {
      item: {
        color: theme.palette.primary.contrastText,
        backgroundColor: () => {
          if (schedule.multiplier < 1) {
            return '#673ab7';
          }
          return backgroundColorMap(theme)[schedule.type];
        },
        fontSize: 12,
        margin: 'auto',
        marginTop: '1px',
        marginBottom: '1px',
        padding: '3px',
        textAlign: 'center',
        minWidth: '100px',
        maxWidth: '100px',
        overflowWrap: 'break-word',
        borderRadius: 10,
        '&:hover': {
          backgroundColor: () => {
            if (schedule.multiplier < 1) {
              return '#512da8';
            }
            return hoverBackgroundColorMap[schedule.type];
          },
        },
      },
      itemNote: {
        borderTop: '1px solid white',
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        margin: '2px',
        fontWeight: 'normal',
      },
    };
  });

function ScheduleItem({ job: schedule, openDialog }) {
  const classes = useStyles(schedule)();

  const handleClick = () => {
    openDialog();
  };

  if (schedule.note?.length > 0) {
    return (
      <div>
        <div className={classes.item} onClick={handleClick}>
          {getName(schedule)}
          <div className={classes.itemNote}>{schedule.note}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.item} onClick={handleClick}>
      {getName(schedule)}
    </div>
  );
}

export default ScheduleItem;
