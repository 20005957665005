import { authHeader, handleResponse } from '../helpers/authentication'

export const floorService = {
    get,
    getAll,
    create,
    update,
    deleteFloor,
    uploadFile,
    deleteFile,
    getForAnalysis
}

function get(id) {
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/${id}`, requestOptions).then(handleResponse)     
}

function getAll(buildingId) {   
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/byBuilding/${buildingId}`, requestOptions).then(handleResponse)     
}

function create(floor) {
    const requestOptions =
    {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(floor)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors`, requestOptions).then(handleResponse)        
}

function update(floor) {
    const requestOptions =
    {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(floor)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/${floor.id}`, requestOptions).then(handleResponse)        
}

function deleteFloor(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: {
            ...authHeader(),            
        },
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/${id}`, requestOptions).then(handleResponse)        
}

function uploadFile(id, formData) {
    formData.append("id", id);
    const requestOptions =
    {
        method: 'POST',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/Files`, requestOptions).then(handleResponse)

}

function deleteFile(id) {
    const requestOptions =
    {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/Files/${id}`, requestOptions).then(handleResponse)
}

function getForAnalysis(params, buildingId) {
    var paramArray = params === undefined ? [] : params
    var paramString = '?'
    paramArray.forEach(p => paramString = (p.value !== null && p.value !== undefined) ? paramString.concat(`${p.name}=${p.value}&`) : paramString);
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/Floors/Analysis/${buildingId}${paramString}`, requestOptions).then(handleResponse)
}