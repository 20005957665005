import React, { useEffect, useState } from 'react';
import { buildingService } from '../../services/buildingService';
import FancyBuilding from './FancyBuilding';
import { makeStyles } from '@material-ui/core';

const MonitoringManager = (props) => {

    const { jobId } = props

    const classes = useStyles()

    const [buildings, setBuildings] = useState([])

    useEffect(() => {
        buildingService.getAll(jobId)
            .then(res => setBuildings(res))
    }, [jobId])

    return (
        <div className={classes.container}>
            {
                buildings.map(building =>
                    <FancyBuilding
                        key={building.id}
                        building={building}
                    />)
            }
        </div>
    )
}

export default MonitoringManager;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
}));