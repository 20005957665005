import React, { useState, useEffect } from 'react';
import SVGComponent from './SVGComponent';
import { getCurrentUser } from '../../helpers/authentication';
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Popover,
} from '@material-ui/core';
import { windowService } from '../../services/windowService';
import { floorService } from '../../services/floorService';
import { buildingService } from '../../services/buildingService';
import { useHistory, useParams } from 'react-router-dom';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import GetAppIcon from '@material-ui/icons/GetApp';
import { percentages } from './Windows';
import { Role } from '../../helpers/role';

const MonitoringMap = (props) => {
  const { data, editing, getWindows, setSnackbar, handleGlobal, jobId, getJob } = props;

  const classes = useStyles();
  const { floorId } = useParams();
  const history = useHistory();

  const user = getCurrentUser();

  const [text, setText] = useState(false);
  const [mark, setMark] = useState(true);

  const [building, setBuilding] = useState({
    name: '',
  });
  const [floor, setFloor] = useState({
    id: 0,
    name: '',
    percentage: 0,
    imageWidth: 0,
    imageHeight: 0,
  });

  const [floors, setFloors] = useState([]);

  useEffect(() => {
    floorService
      .get(floorId)
      .then((res) => {
        setFloor({ ...res });
        buildingService.get(res.buildingId).then((res2) => setBuilding(res2));
        floorService.getAll(res.buildingId).then((res3) => setFloors(res3));
      })
      .catch(() => handleGlobal());
    getWindows(floorId);
  }, [floorId]);

  const imagePath = `${process.env.REACT_APP_API_URL}api/Floors/Files/${floor.id}?token=${user.token}`;

  const handleSave = () => {
    let dataToSend = [];
    data.forEach((window) => {
      dataToSend.push({
        id: window.id,
        x: window.x,
        y: window.y,
      });
    });
    windowService.updatePositions(dataToSend).then(() => {
      setSnackbar({
        open: true,
        message: 'Enregistrement réussi',
        variant: 'success',
      });
    });
  };

  const downloadSvg = () => {
    fetch(imagePath)
      .then((response) => response.blob())
      .then((response) => {
        let reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          let base64data = reader.result;
          let svgData = document.querySelector('#svg').outerHTML;
          let svgBlob = new Blob(
            [fixSvg(svgData, floor.imageWidth, floor.imageHeight, base64data)],
            { type: 'image/svg+xml;charset=utf-8' }
          );
          let svgUrl = URL.createObjectURL(svgBlob);
          let canvas = document.createElement('canvas');
          canvas.width = floor.imageWidth;
          canvas.height = floor.imageHeight;
          let ctx = canvas.getContext('2d');
          let img = new Image();
          img.onload = () => {
            ctx.drawImage(img, 0, 0);
            let png = canvas.toDataURL('image/jpeg', 1.0);
            let downloadLink = document.createElement('a');
            downloadLink.href = png;
            downloadLink.download = `${building.name} ${floor.name}.jpg`;
            downloadLink.click();
            URL.revokeObjectURL(png);
          };
          img.src = svgUrl;
        };
      });
  };

  const fixSvg = (str, width, height, base64data) => {
    return str
      .replaceAll(
        /<svg[^>]*><g[^>]*>/g,
        `<svg height="${height}" width="${width}" viewBox="0 0 ${width} ${height}" 
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <style>text {font-family:Arial;} </style><g transform="translate(0,0) scale(1)">`
      )
      .replaceAll(
        /<image[^>]*>/g,
        `<image xlink:href="${base64data}" width="${width}" height="${height}">`
      )
      .replaceAll(/==/g, '');
  };

  const changeFloor = (id) => {
    history.replace(`/surveillance/${jobId}/map/${id}`);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuData, setMenuData] = useState(null);

  const handleChange = async (e) => {
    const newValue = e.target.value;
    menuData.percentage = +newValue;
    try {
      await windowService.update(menuData);
      await getJob(jobId);
      setFloor({ ...(await floorService.get(floorId)) });
      setAnchorEl(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    floor.id && (
      <div className={classes.container}>
        <Paper className={classes.actions}>
          <Typography variant='h6'>{`${building.name}`}</Typography>
          <FormControl margin='normal' className={classes.select}>
            <InputLabel>Etage</InputLabel>
            <Select value={floorId} onChange={(e) => changeFloor(e.target.value)}>
              {floors.map((floor) => (
                <MenuItem key={floor.id} value={floor.id}>
                  {floor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant='h6'>{`${Number(floor.percentage).toFixed(2)}%`}</Typography>
          <div className={classes.grower} />
          <FormControlLabel
            control={
              <Checkbox
                checked={mark}
                onChange={(e) => setMark(e.target.checked)}
                value={mark}
                color='primary'
              />
            }
            label={<div>Étiquettes</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={text}
                onChange={(e) => setText(e.target.checked)}
                value={text}
                color='primary'
              />
            }
            label={<div className={classes.button}>Texte</div>}
          />
          {editing && (
            <div>
              <Tooltip title='Annuler'>
                <IconButton variant='contained' onClick={() => getWindows(floor.id)}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Enregistrer'>
                <IconButton variant='contained' onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Tooltip title='Retour aux bâtiments'>
            <IconButton
              variant='contained'
              onClick={() => handleGlobal(building.id, floorId)}
              className={classes.button}>
              <BusinessIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.button}></div>
          <IconButton id='zoom_in'>
            <ZoomInIcon />
          </IconButton>
          <IconButton id='zoom_out'>
            <ZoomOutIcon />
          </IconButton>
          <Tooltip title='Telecharger'>
            <IconButton onClick={downloadSvg}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Paper>
        <SVGComponent
          data={data}
          editing={editing}
          width={floor.imageWidth}
          height={floor.imageHeight}
          imagePath={imagePath}
          mark={mark}
          text={text}
          setAnchorEl={setAnchorEl}
          setMenuData={setMenuData}
        />
        {getCurrentUser().role === Role.Admin && (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <FormControl margin='normal' className={classes.menu}>
              <InputLabel>Posé %</InputLabel>
              <Select
                value={data.find((window) => window.id === menuData?.id)?.percentage}
                onChange={handleChange}>
                {Object.keys(percentages).map((key, i) => (
                  <MenuItem key={i} value={key}>
                    {percentages[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Popover>
        )}
      </div>
    )
  );
};

export default MonitoringMap;

const useStyles = makeStyles((theme) => ({
  actions: {
    width: 900,
    textAlign: 'right',
    padding: '4px 8px',
    margin: 'auto',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    textAlign: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  grower: {
    flexGrow: 1,
  },
  select: {
    marginTop: 0,
    minWidth: 100,
    width: 100,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
  menu: {
    marginTop: 8,
    minWidth: 120,
    width: 120,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
}));
