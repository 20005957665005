import React, { useEffect, useState } from 'react'
import Dashboard from '../components/Dashboard'
import moment from 'moment'
import MaterialTable, { MTableCell } from 'material-table'
import { Localization } from '../helpers/localization'
import { useHistory } from 'react-router-dom'
import { reserveService } from '../services/reserveService'
import ReserveAddDialog from '../dialogs/ReserveAddDialog'
import { ReserveStatus, ReserveStatusFR, ReserveStatusList } from '../helpers/reserveStatus'
import { jobService } from '../services/jobService'
import { JobStatus } from '../helpers/jobStatus'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog'
import { getTableBodyHeights } from '../helpers/table'

const Reserves = () => {
    const history = useHistory()
    const columns = [
        {
            title: 'Date de demande',
            field: 'date',
            type: 'date',
            dateSetting: { locale: 'fr-FR' },
            render: rowData => { return rowData.date && <div>{moment(rowData.date).format('DD.MM.YYYY')}</div> }
        },
        { title: 'Chantier', field: 'jobName', },
        { title: 'Adresse', field: 'address' },
        {
            title: 'Statut',
            field: 'status',
            render: rowData => <div>{ReserveStatusFR[rowData.status]}</div>,
            customFilterAndSearch: (term, rowData) => ReserveStatusFR[rowData.status].toUpperCase().includes(term.toUpperCase())
        },
    ]

    const options = {
        draggable: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 25, 50],
        paging: true,
        pageSize: 10,
        ...getTableBodyHeights(),
    }

    const actions = [
        {
            icon: 'add_box',
            tooltip: 'Ajouter',
            isFreeAction: true,
            onClick: (event) => {
                setOpenAdd(true);
            }
        },
        rowData => ({
            icon: 'more_horiz',
            tooltip: 'Détails',
            onClick: (event, rowData) => {
                history.push(String(rowData.id))
            }
        }),
        rowData => ({
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
                setDeleteId(rowData.id);
                setConfirmOpen(true);
            }
        }),
    ]

    const [reserveToAdd, setReserveToAdd] = useState({
        status: ReserveStatus.InProgress,
        date: moment().format('YYYY-MM-DD'),
        jobName: '',
        address: '',
        location: '',
        contactInfo: '',
        note: ''
    })
    const handleAddReserve = () => {
        reserveService.create(reserveToAdd)
            .then(() => getReserves())
        handleCloseAdd()
    }

    const [openAdd, setOpenAdd] = useState(false)
    const handleCloseAdd = () => {
        setReserveToAdd({
            status: ReserveStatus.InProgress,
            date: moment().format('YYYY-MM-DD'),
            jobName: '',
            address: '',
            location: '',
            contactInfo: '',
            note: ''
        })
        setOpenAdd(false)
    }

    const [filter, setFilter] = useState({
        status: ''
    })

    const [loading, setLoading] = useState(false)
    const [reserves, setReserves] = useState([])
    const getReserves = (params) => {
        setLoading(true)
        reserveService.getAll(params)
            .then(response => {
                setReserves(response)
                setLoading(false)
            })
    }
    useEffect(() => {
        getReserves([{ name: 'status', value: filter.status }])
        getJobs([{ name: 'status', value: JobStatus.Started }])
    }, [filter])

    const [jobs, setJobs] = useState([])
    const getJobs = (params) => {
        jobService.getAll(params)
            .then(res => setJobs(res))
    }

    const handleStatusChange = (e) => {
        const value = e.target.value
        setFilter({ ...filter, status: value })
    }

    const [deleteId, setDeleteId] = useState(0)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }
    const handleDeleteReserve = () => {
        reserveService.deleteReserve(deleteId)
            .then(() => {
                handleConfirmClose()
                getReserves()
            })
    }

    const classes = useStyles()

    return (
        <Dashboard title={'SAV et Reserves'}>
            <MaterialTable
                columns={columns}
                options={options}
                localization={Localization}
                actions={actions}
                data={reserves}
                isLoading={loading}
                title={
                    <FormControl margin="normal" className={classes.status}>
                        <InputLabel>Statut</InputLabel>
                        <Select
                            value={filter.status}
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={''}>Tout</MenuItem>
                            {ReserveStatusList.map((status, i) =>
                                <MenuItem key={i} value={status}>{ReserveStatusFR[status]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
                components={{
                    Cell: props => <MTableCell {...props} style={{ paddingTop: 4, paddingBottom: 4 }} />
                }}
            />
            <ReserveAddDialog
                open={openAdd}
                handleClose={handleCloseAdd}
                reserveToAdd={reserveToAdd}
                setReserveToAdd={setReserveToAdd}
                handleAdd={handleAddReserve}
                jobs={jobs}
            />
            <ConfirmDeleteDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleDelete={handleDeleteReserve}
                text={'Confirmer la suppression de reserve?'}
            />
        </Dashboard>
    )
}

export default Reserves

const useStyles = makeStyles(theme => ({
    status: {
        marginTop: 0,
        minWidth: 200,
        width: 200,
    }
}));