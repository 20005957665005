import React, {  useState } from 'react';
import MaterialTable, { MTableCell } from 'material-table';
import { Localization } from '../../helpers/localization';
import { getCurrentUser } from '../../helpers/authentication';
import { FileUploadCell } from '../FileUploadCell';
import { useHistory } from 'react-router-dom';
import { floorService } from '../../services/floorService';
import ConfirmDeleteDialog from '../../dialogs/ConfirmDeleteDialog';

const Floors = (props) => {
    const { jobId, data, buildingId, loading, setLoading, title, getFloors, floorId, updatePercentages, setSnackbar } = props
    const history = useHistory()

    const columns = [
        { title: 'Nom', field: 'name' },
        {
            title: '%',
            field: 'percentage',
            editable: 'never',
            render: rowData => rowData && <div>{`${Number(rowData.percentage).toFixed(2)}%`}</div>
        },
        {
            title: 'Image',
            field: 'imagePath',
            render: rowData => rowData && FileUploadCell(rowData, rowData.imagePath, getCurrentUser().token, 'api/Floors/Files', handleFileSubmit, handleFileDelete),
            disableClick: true,
            editable: 'never',
            cellStyle: {
                maxWidth: '10px'
            }
        }
    ]

    const handleFileSubmit = id => event => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("file", event.target.files[0])
        setLoading(true)
        floorService.uploadFile(id, formData)
            .then(() => {
                getFloors(buildingId)
            })
    }

    const handleFileDelete = (id) => () => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleDelete = () => {
        floorService.deleteFile(deleteId)
            .then(() => {
                getFloors(buildingId)
                handleClose()
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    variant: 'error',
                    message: err
                })
                setLoading(false);
            })
    }

    const [deleteId, setDeleteId] = useState(0)

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false);
    }

    const options = {
        search: false,
        paging: false,
        maxBodyHeight: 200,
        minBodyHeight: 200,
        draggable: false,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        rowStyle: (rowData) => {
            if (rowData.id === floorId) {
                return {
                    backgroundColor: '#bcbcbc',
                }
            }
        }
    }

    const actions = [
        rowData => {
            if (rowData.imagePath.length !== 0) {
                return {
                    icon: 'business',
                    tooltip: "Plan d'étage",
                    onClick: (event, rowData) => {
                        event.stopPropagation()
                        history.push(`/surveillance/${jobId}/map/${rowData.id}`)
                    }
                }
            }
        }
    ]

    const editable = buildingId ?
        {
            onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    const item = {
                        name: newData.name,
                        percentage: 0,
                        buildingId: +buildingId
                    }
                    floorService.create(item)
                        .then(() => {
                            updatePercentages()
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Entrée invalide'
                            })
                            reject()
                        })
                }),
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    const item = {
                        id: oldData.id,
                        name: newData.name,
                        imagePath: newData.imagePath,
                        imageWidth: newData.imageWidth,
                        imageHeight: newData.imageHeight,
                        buildingId: +oldData.buildingId
                    }
                    floorService.update(item)
                        .then(() => {
                            getFloors(oldData.buildingId)
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Entrée invalide'
                            })
                            reject()
                        })
                }),
            onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    floorService.deleteFloor(oldData.id)
                        .then(() => {
                            updatePercentages()
                            handleRowDelete()
                            resolve()
                        })
                        .catch(() => {
                            setSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Erreur'
                            })
                            reject()
                        })
                }),
        } : null

    const handleRowClick = (_, rowData) => {
        history.replace(`/surveillance/${jobId}/admin/${buildingId}/${rowData.id}`)
    }
    const handleRowDelete = () => {
        history.replace(`/surveillance/${jobId}/admin/${buildingId}`)
    }
    
    return (
        <div>
            <MaterialTable
                columns={columns}
                options={options}
                editable={editable}
                actions={actions}
                data={data}
                dense={true}
                title={`Étages ${title}`}
                localization={Localization}
                isLoading={loading}
                components={{
                    Cell: props => <MTableCell {...props} style={{ padding: '0px 16px' }} />,
                }}
                onRowClick={handleRowClick}
            />
            <ConfirmDeleteDialog
                open={open}
                handleClose={handleClose}
                handleDelete={handleDelete}
                text={'Confirmer la suppression de fichier?'}
            />
        </div>
    )
}

export default Floors;