import { authHeader, handleResponse } from '../helpers/authentication'

export const analysisService = {
    getFiscalMonths,
    getFiscalExpenses
}

function getFiscalMonths(params) {
    var paramArray = params === undefined ? [] : params
    var paramString = '?'
    paramArray.forEach(p => paramString = (p.value !== null && p.value !== undefined) ? paramString.concat(`${p.name}=${p.value}&`) : paramString);
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${process.env.REACT_APP_API_URL}api/Analysis/fiscal${paramString}`, requestOptions).then(handleResponse)
}

function getFiscalExpenses(params) {
    var paramArray = params === undefined ? [] : params
    var paramString = '?'
    paramArray.forEach(p => paramString = (p.value !== null && p.value !== undefined) ? paramString.concat(`${p.name}=${p.value}&`) : paramString);
    const requestOptions =
    {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${process.env.REACT_APP_API_URL}api/Analysis/fiscal/expenses${paramString}`, requestOptions).then(handleResponse)
}